import React, {useCallback, useEffect, useState} from 'react';
import {Menu} from 'shared/components/HeaderMenu/Menu';
import {isMobile} from 'react-device-detect';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {Dropdown} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {MenuInterface} from 'shared/components/HeaderMenu/MenuItem';
import styled from 'styled-components';

interface WithLoadingProps {
    topLevelMenu: MenuInterface;
    children: React.ReactNode;
}

export const MenuWithSubmenu = ({topLevelMenu, children}: WithLoadingProps) => {
    const isSmallDevice = isDeviceSmall();
    const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);

    const toggleMenu = useCallback(
        (state: boolean) => () => {
            setIsSubmenuVisible(state);
        },
        []
    );

    const toggleMenuOnClick = useCallback(
        (event: React.MouseEvent) => {
            if (isMobile || isSmallDevice) {
                event.preventDefault();

                if (event.target === event.currentTarget) {
                    setIsSubmenuVisible(
                        (isSubmenuVisible) => !isSubmenuVisible
                    );
                }
            }
        },
        [isSmallDevice, isMobile]
    );

    const hideSubMenu = useCallback((event: MouseEvent) => {
        const mainMenu = (event.target as HTMLElement).closest('.main-menu');

        if (!mainMenu) {
            setIsSubmenuVisible(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('click', hideSubMenu);

        return () => {
            document.removeEventListener('click', hideSubMenu);
        };
    }, []);

    if (
        topLevelMenu.hasOwnProperty('submenu') &&
        typeof topLevelMenu.submenu !== 'undefined' &&
        topLevelMenu.submenu.length > 0
    ) {
        return (
            <Item as="div" className="dropdown-item">
                <Dropdown
                    show={isSubmenuVisible}
                    drop={isMobile || isSmallDevice ? 'end' : 'start'}
                    onMouseEnter={toggleMenu(true)}
                    onMouseLeave={toggleMenu(false)}>
                    <Dropdown.Toggle
                        onClick={toggleMenuOnClick}
                        role="submenu-toggle">
                        <Icon iconName={topLevelMenu.icon} />
                        {topLevelMenu.name}
                    </Dropdown.Toggle>
                    <DropdownMenu menu={topLevelMenu.submenu} />
                </Dropdown>
            </Item>
        );
    }

    return children;
};

const DropdownMenu = styled(({...props}) => <Menu {...props} />)`
    position: absolute !important;
    inset: 0 !important;
    transform: translateY(0) translateX(-100%) !important;
`;

const Item = styled(Dropdown.Item)`
    padding: 0 !important;

    > div > button {
        border: 0 !important;
        background: inherit !important;
        font-size: 1em !important;

        > img {
            width: 20px;
            height: 20px;
        }
    }
`;
