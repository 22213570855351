import React, {useState, useCallback, forwardRef, ForwardedRef} from 'react';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import MobileOptions from 'shared/components/Table/MobileOptions';
import TableOptions from 'shared/components/Table/TableOptions';
import type {
    TableRowType as RowType,
    FieldType,
    OptionsType,
} from 'shared/types';
import Row, {DndRow} from 'shared/components/Table/Row';
import {FieldRender} from 'shared/components/Table/FieldRender';
import {DropProps} from 'shared/components/DragAndDrop/withDropTarget';

interface TableRowType<T> extends DropProps<T> {
    row: RowType;
    fields: FieldType[];
    options: OptionsType;
    colspan: number;
    index: number;
}

const TableRow = <T extends RowType>(
    {
        row,
        fields,
        options,
        colspan,
        index,
        isDnD,
        findItem,
        moveItem,
    }: TableRowType<T>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const isSmallDevice = isDeviceSmall();
    const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState<boolean>(false);

    const rowActionHandler = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (options.options && options.options.buttons) {
                const buttonWithAction = options.options.buttons
                    .filter((button) => {
                        if (button.hasOwnProperty('hidden')) {
                            return !button.hidden;
                        }

                        if (button.hasOwnProperty('isHidden')) {
                            return !button.isHidden(row);
                        }

                        return true;
                    })
                    .find((button) => button.rowAction);

                if (buttonWithAction && buttonWithAction.action) {
                    buttonWithAction.action(row, event);
                }
            }
        },
        [options]
    );

    const fieldsGeneric = fields.filter(
        (field) =>
            !field.hasOwnProperty('colSpan') ||
            (field.hasOwnProperty('colSpan') && field.colSpan <= 1)
    );

    let totalOffset = 0;
    const fieldsColspan = fields.filter((field) => {
        if (field.hasOwnProperty('colSpan') && field.colSpan > 1) {
            let colspan = field.colSpan;
            if (typeof field.colSpan === 'string') {
                colspan = parseInt(field.colSpan);
            }

            totalOffset += colspan;

            return true;
        }
        return false;
    });

    const fieldRender = (
        <>
            <FieldRender
                cols={fieldsGeneric}
                rowIndex={index}
                row={row}
                forwardedRef={ref}
            />
            {typeof options.options !== 'undefined' ? (
                <TableOptions
                    hasExtraRow={fieldsColspan.length}
                    options={options.options}
                    row={row}
                    isSmallDevice={isSmallDevice}
                    isOptionsMenuOpen={isOptionsMenuOpen}
                    setIsOptionsMenuOpen={setIsOptionsMenuOpen}
                />
            ) : null}
        </>
    );

    return (
        <>
            {isDnD ? (
                <DndRow
                    draggable={row}
                    findItem={findItem}
                    moveItem={moveItem}
                    options={options}
                    onClick={rowActionHandler}
                    className={index % 2 === 0 ? 'even' : 'odd'}>
                    {fieldRender}
                </DndRow>
            ) : (
                <Row
                    options={options}
                    onClick={rowActionHandler}
                    className={index % 2 === 0 ? 'even' : 'odd'}>
                    {fieldRender}
                </Row>
            )}

            {fieldsColspan.length ? (
                <tr className={index % 2 === 0 ? 'even' : 'odd'}>
                    <FieldRender
                        cols={fieldsColspan}
                        rowIndex={index}
                        row={row}
                        forwardedRef={ref}
                    />
                </tr>
            ) : null}
            {typeof options.options !== 'undefined' && isSmallDevice ? (
                <MobileOptions
                    className={
                        fieldsColspan.length
                            ? index % 2 === 0
                                ? 'even'
                                : 'odd'
                            : ''
                    }
                    colspan={totalOffset > 0 ? totalOffset + 1 : colspan}
                    options={options.options}
                    row={row}
                    isOptionsMenuOpen={isOptionsMenuOpen}
                />
            ) : null}
        </>
    );
};

export default forwardRef<HTMLDivElement, TableRowType<RowType>>(TableRow);
