import React, {useCallback, useMemo, useState} from 'react';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {MoreInfoModal} from 'shared/components/MoreInfo/MoreInfoModal';
import {ButtonWithPopover} from './ButtonWithPopover';
import {CSSProperties} from 'styled-components';
import {MoreInfoScope} from 'shared/components/MoreInfo/helpers/useMoreInfo';

interface MoreInfoProps {
    hoverText?: string;
    info: number | string;
    className?: string;
    visible?: boolean;
    minUsage?: boolean;
    scope?: MoreInfoScope;
    dynamicposition?: boolean;
    disabled?: boolean;
    styleOverride?: CSSProperties;
    editbuttonVisible?: boolean;
    dialogClassName?: string;
}

export const MoreInfo = ({
    hoverText,
    info,
    className,
    visible = false,
    minUsage = false,
    editbuttonVisible = true,
    scope,
    dynamicposition = false,
    disabled = false,
    styleOverride,
    dialogClassName,
}: MoreInfoProps) => {
    const isSmallDevice = isDeviceSmall();
    const [show, setShow] = useState(visible);

    const handleClose = useCallback(() => {
        setShow(false);
    }, []);

    const handleShow = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setShow(true);
    }, []);

    const tooltipText = useMemo(() => {
        return typeof hoverText !== 'undefined' ? hoverText : 'More Info';
    }, [hoverText]);

    const htmlClassName = useMemo(() => {
        let htmlClassName =
            typeof className !== 'undefined'
                ? className + ' info-modal'
                : 'info-modal';

        if (isSmallDevice) htmlClassName += ' mobile';

        return htmlClassName;
    }, [isSmallDevice, className]);

    return (
        <div
            className="more-info"
            style={{
                position:
                    dynamicposition === true
                        ? 'relative'
                        : minUsage
                        ? 'initial'
                        : 'absolute',
                ...(styleOverride || {}),
            }}>
            {show ? (
                <MoreInfoModal
                    handleClose={handleClose}
                    tooltipText={tooltipText}
                    htmlClassName={htmlClassName}
                    dialogClassName={dialogClassName}
                    editbuttonVisible={editbuttonVisible}
                    info={info}
                    scope={scope}
                />
            ) : null}

            <ButtonWithPopover
                handleShow={handleShow}
                minUsage={minUsage}
                dynamicposition={dynamicposition}
                disabled={disabled}
                tooltipText={tooltipText}
            />
        </div>
    );
};
