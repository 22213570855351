import React, {useCallback, useMemo} from 'react';
import {
    logoutAction,
    redirectToSet,
} from 'components/customer/Auth/store/authSlice';
import {useAppContext} from 'contexts';
import {Dropdown} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {useAppDispatch} from 'store/customer';
import {Link} from 'react-router-dom';
import {MenuWithSubmenu} from 'shared/components/HeaderMenu/MenuWithSubmenu';
import {useContactUsPopup} from 'shared/components/HeaderMenu/helper/useContactUsPopup';

export interface MenuInterface {
    name: string;
    icon: string;
    url?: string;
    logout?: boolean;
    modal?: boolean;
    submenu?: MenuInterface[];
    external?: boolean;
}

interface MenuItemProps {
    menu: MenuInterface;
}

export const MenuItem = ({menu}: MenuItemProps) => {
    const dispatch = useAppDispatch();
    const {dialog, showContactInfo} = useContactUsPopup();
    const {
        userProfile: {
            allowDirectLogin,
            parentApplicationUrl,
            parentApplicationName,
        },
    } = useAppContext();

    const signout = () => {
        if (!allowDirectLogin && !!parentApplicationUrl) {
            dispatch(redirectToSet(parentApplicationUrl));
        }
        void dispatch(logoutAction());
    };

    const menuLabel = useMemo(() => {
        if (menu.logout && !allowDirectLogin && !!parentApplicationName) {
            return `Return to ${parentApplicationName}`;
        }

        return menu.name;
    }, [menu, allowDirectLogin, parentApplicationName]);

    const clickHandler = useCallback(() => {
        if (menu.modal) {
            showContactInfo();
        } else if (menu.logout) {
            void signout();
        }
    }, [menu, showContactInfo, signout]);

    return (
        <>
            {dialog}
            <MenuWithSubmenu topLevelMenu={menu}>
                {menu.external ? (
                    <Dropdown.Item
                        onClick={clickHandler}
                        as="a"
                        href={menu.url}
                        target="_blank">
                        <Icon iconName={menu.icon} /> {menuLabel}
                    </Dropdown.Item>
                ) : (
                    <Dropdown.Item
                        onClick={clickHandler}
                        as={Link}
                        to={menu.url}>
                        <Icon iconName={menu.icon} /> {menuLabel}
                    </Dropdown.Item>
                )}
            </MenuWithSubmenu>
        </>
    );
};
