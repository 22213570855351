import {useJobContext} from 'contexts';
import React from 'react';
import {formatPrice} from 'shared/helpers';
import {MoreInfo} from 'shared';
import {VariationCostMoreInfo} from 'shared/components/Header/VariationCostMoreInfo';

export const VariationCost = () => {
    const {job} = useJobContext();

    return job.variationCost ? (
        <>
            <li>
                <span style={{display: 'flex'}}>
                    <strong>Total Variation Cost:</strong>
                    {job.rooms && (
                        <MoreInfo
                            minUsage={true}
                            editbuttonVisible={false}
                            hoverText="Total Variation Costs"
                            dialogClassName="modal-md"
                            info={<VariationCostMoreInfo />}
                        />
                    )}
                </span>
                <span>
                    <strong>{formatPrice(job.totalVariationCost, job)}</strong>
                </span>
            </li>
        </>
    ) : (
        <>
            {job.totalVariationCost > 0 ? (
                <li>
                    <span style={{display: 'flex'}}>
                        <strong>Total Variation Cost:</strong>
                        {job.rooms && (
                            <MoreInfo
                                minUsage={true}
                                editbuttonVisible={false}
                                hoverText="Total Variation Costs"
                                dialogClassName="modal-md"
                                info={<VariationCostMoreInfo />}
                            />
                        )}
                    </span>
                    <span>
                        <strong>
                            {formatPrice(job.totalVariationCost, job)}
                        </strong>
                    </span>
                </li>
            ) : (
                <></>
            )}
        </>
    );
};
