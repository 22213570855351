import React from 'react';
import {Button, Form} from 'react-bootstrap';
import {CBCButton, CBCButtonProps, Loader} from 'shared/helpers';
import {ProductBackgroundImage} from 'components';
import {ItemColumn} from 'shared/components/Product/ItemColumn';
import styled from 'styled-components';
import {QuickAdd} from 'components/customer/Product/QuickAdd';
import {useItem} from 'shared/components/Product/helpers/useItem';
import {isDeviceMedium} from 'shared/helpers/DeviceSize';
import {useJobContext} from 'contexts';
import {PartialRoom} from 'shared/types/PartialRoom';
import {useMouseOver} from 'shared/helpers/useMouseOver';

export type ItemType = {
    favourites: number;
    id: number;
    sundryCategoryId: number;
    name: string;
    changed_name: string;
    changedImage: string;
    imageUrl: string;
    stockStatus: number;
    stockStatusText: string;
    advanced?: boolean;
    hidden: boolean;
    defaultWidth: number;
};

type ItemProps = {
    item: ItemType;
    isProduct: boolean;
    setError?: (errors: string[]) => void;
    loader?: boolean;
};

export const Item = ({item, isProduct, setError}: ItemProps) => {
    const mediumDevice = isDeviceMedium();
    const {room} = useJobContext() as {room: PartialRoom};
    const {isMouseOverComponent, handleMouseEnter, handleMouseExit} =
        useMouseOver();
    const {
        loading,
        isFavourite,
        style,
        favouriteButtonDisabled,
        cartButtonDisabled,
        count,
        increment,
        decrement,
        itemClickHandler,
        addToFavouriteHandler,
        countChangeHandler,
        hardwareLinkFormatter,
        addToCartHandler,
    } = useItem(item, isProduct, setError);

    return (
        <Loader loader={loading}>
            {!item.hidden && (
                <ItemColumn $hardware={!isProduct}>
                    <HardwareItem
                        className="hardwareItem"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseExit}>
                        <Details
                            onClick={itemClickHandler}
                            className={
                                (isProduct
                                    ? 'details'
                                    : 'details hardware-details') +
                                (mediumDevice ? ` no-padding` : '')
                            }>
                            <Button
                                onClick={addToFavouriteHandler}
                                disabled={favouriteButtonDisabled}
                                variant="btn btn-link"
                                className={
                                    isFavourite
                                        ? 'itemFavourite userFavourite'
                                        : 'itemFavourite'
                                }
                            />

                            <ImageContainer>
                                {item.hasOwnProperty('imageUrl') ? (
                                    <ProductBackgroundImage
                                        alt={item.name}
                                        image={style.backgroundImage || ''}
                                        transform={parseInt(room.extHorGrain)}
                                        src={item.changedImage}
                                        fallbackSrc={item.imageUrl}
                                        resize={true}
                                        isHardware={!isProduct}
                                    />
                                ) : null}
                            </ImageContainer>

                            <Label className="item-name">
                                {item.changed_name
                                    ? item.changed_name
                                    : item.name}{' '}
                                {item.hasOwnProperty('stockStatus') &&
                                item.stockStatus == 1
                                    ? ` (${item.stockStatusText})`
                                    : ''}
                            </Label>
                        </Details>
                        {isProduct ? null : (
                            <div className="cartButtons">
                                {item.hasOwnProperty('advanced') &&
                                item.advanced ? (
                                    <HardwareButton
                                        disabled={cartButtonDisabled}
                                        asLink={true}
                                        to={hardwareLinkFormatter()}
                                        className="button-blue btn-sm"
                                        iconName="Button-Add.svg">
                                        Click for More Options
                                    </HardwareButton>
                                ) : (
                                    <>
                                        <HardwareButton
                                            disabled={cartButtonDisabled}
                                            onClick={addToCartHandler}
                                            className="button-blue btn-sm"
                                            iconName="Button-Add.svg">
                                            Add to Cart
                                        </HardwareButton>
                                        <div className="numberSpinner">
                                            <label>Qty:</label>
                                            <Form.Control
                                                as="input"
                                                type="number"
                                                value={count}
                                                onChange={countChangeHandler}
                                            />
                                            <div className="spinner">
                                                <div onClick={increment}>
                                                    &and;
                                                </div>
                                                <div onClick={decrement}>
                                                    &or;
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}

                        {isProduct ? (
                            <QuickAdd
                                item={item}
                                open={isMouseOverComponent}
                                onClickHandler={itemClickHandler}
                            />
                        ) : null}
                    </HardwareItem>
                </ItemColumn>
            )}
        </Loader>
    );
};

const HardwareItem = styled.div`
    position: relative;
`;

const Label = styled.div`
    font-size: 0.87em;
`;

const Details = styled.div`
    display: flex;
`;

interface HardwareButtonProps extends CBCButtonProps {
    $margin?: boolean;
}

export const HardwareButton = styled((props: HardwareButtonProps) => (
    <CBCButton {...props} />
))`
    margin: ${({$margin = true}) =>
        $margin ? '0 5px !important' : '0 !important'};
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
        line-height: 10px;
    }
`;

const ImageContainer = styled.div`
    width: 100%;
    height: inherit;

    flex: 1;
    align-content: center;
`;
