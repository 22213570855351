import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {Dropdown as DropdownCBC, Button, Spinner} from 'react-bootstrap';
import {randomString} from 'shared/helpers';
import {useNotificationContext, useAppContext} from 'contexts';
import {OverlayTrigger, DropdownListItem} from 'shared';
import {DropdownType} from 'shared/components/Forms/DropdownListItem';
import DropdownMenu from 'shared/components/Forms/DropdownMenu';
import {validationErrorAppliesToField} from 'shared/validator';
import styled, {css} from 'styled-components';

export interface DropdownOption {
    id?: string | number;
    name?: string;
    value: string | number;
    disabled?: boolean;
    selected?: boolean;
    [key: string]: string | number | boolean;
}

interface DropdownInterface {
    id?: string;
    options: DropdownOption[];
    value: string | number;
    placeholder?: string;
    name: string;
    disabled?: boolean;
    field?: string;
    setFieldValue?: (name: string, value: string | number | boolean) => void;
    selectHandler?: (
        name: string,
        value: string | number | boolean,
        options?: DropdownOption[]
    ) => void;
    extraHandler?: (
        name: string,
        value: string | number,
        options?: DropdownOption[]
    ) => void;
    loader?: boolean;
    isInvalid?: boolean;
    showDialog?: unknown;
    hideDialog?: unknown;
    setCurrentTab?: unknown;
    formStructure?: unknown;
    defaultHingePosition?: unknown;
    ogHandler?: unknown;
    randomId?: string;
    fieldSetIndex?: number;
    type?: DropdownType;
    autoSelect?: boolean;
    autoWidth?: boolean;
    fullWidth?: boolean;
    fieldIndex?: number;
    showLoader?: boolean;
    fixedWidth?: boolean;
}

export const Dropdown = ({
    options = [] as DropdownOption[],
    value: ogValue,
    placeholder = '',
    name,
    disabled = false,
    field = 'name',
    setFieldValue = undefined,
    selectHandler,
    extraHandler = undefined,
    loader,
    isInvalid = false,
    randomId = '',
    type = undefined,
    autoSelect = true,
    fieldSetIndex,
    autoWidth = false,
    fullWidth = true,
    showDialog = undefined,
    hideDialog = undefined,
    setCurrentTab = undefined,
    formStructure = undefined,
    defaultHingePosition = undefined,
    ogHandler = undefined,
    fieldIndex,
    showLoader = false,
    fixedWidth = false,
    ...props
}: DropdownInterface) => {
    const value =
        !isNaN(ogValue) && ogValue !== null && ogValue !== ''
            ? parseInt(ogValue)
            : ogValue;

    const [selectedText, setSelectedText] = useState<string>(placeholder);
    const [tooltip, setTooltip] = useState<JSX.Element | null>();

    const {messages} = useNotificationContext();
    const {smallLayout} = useAppContext();

    const getOptionByValue = (value: string | number) => {
        if (
            typeof value === 'undefined' ||
            typeof options === 'undefined' ||
            (options && options.length === 0)
        ) {
            return null;
        }

        return options.find((option) =>
            typeof option.id === 'undefined'
                ? option[String(field)] == value
                : option.id == value
        );
    };

    const getOption = () => {
        const allEnabledOptions =
            options &&
            options.filter(
                (option) =>
                    !option.hasOwnProperty('disabled') ||
                    (option.hasOwnProperty('disabled') && !option.disabled)
            );

        if (allEnabledOptions && allEnabledOptions.length) {
            return allEnabledOptions[0];
        }

        return null;
    };

    const defaultSelected = useMemo(() => {
        if (options) {
            const defaultOption = options.find(
                (option) => option.hasOwnProperty('selected') && option.selected
            );

            if (defaultOption) return defaultOption;
        }

        return getOption();
    }, [options]);

    useEffect(() => {
        if (
            isInvalid &&
            messages &&
            Array.isArray(messages.errors) &&
            messages.errors.length
        ) {
            const tooltips = messages.errors
                .filter((error) =>
                    validationErrorAppliesToField(error, name, fieldSetIndex)
                )
                .map((error) => error.message);

            let toolTip = null;

            if (tooltips.length) {
                if (smallLayout && tooltips.length > 0) {
                    tooltips[0] = `* ${tooltips[0]}`;
                    toolTip = <>{tooltips.join('\n* ')}</>;
                } else {
                    toolTip = (
                        <ul>
                            {tooltips.map((tooltip, index) => {
                                return <li key={index}>{tooltip}</li>;
                            })}
                        </ul>
                    );
                }
            }

            setTooltip(toolTip);
        }
    }, [messages, smallLayout]);

    useEffect(() => {
        const selectedOption = getOptionByValue(value);

        if (loader) {
            setSelectedText('Loading...');
            return;
        }

        if (selectedOption != null) {
            if (selectedOption?.disabled) {
                const option = getOption();

                if (option) {
                    if (typeof setFieldValue !== 'undefined')
                        setFieldValue(name, option.id);
                    else {
                        selectHandler(name, option.id, options);
                    }
                }
            } else {
                setSelectedText(String(selectedOption[String(field)]));

                if (extraHandler) {
                    const selectedValue = selectedOption.id;

                    extraHandler(name, selectedValue, options);
                }
            }
        } else {
            if (autoSelect && defaultSelected) {
                setSelectedText(String(defaultSelected[String(field)]));
                const value = !defaultSelected.hasOwnProperty('id')
                    ? defaultSelected[String(field)]
                    : defaultSelected.id;

                if (typeof setFieldValue !== 'undefined')
                    setFieldValue(name, value);
                else {
                    selectHandler(name, value, options);
                }
            } else {
                setSelectedText(placeholder);
            }
        }
    }, [options, loader, autoSelect]);

    useEffect(() => {
        if (selectedText == '') {
            setSelectedText(placeholder);
        }
    }, [placeholder]);

    useEffect(() => {
        if (options.length) {
            const selectedOption = getOptionByValue(value);

            if (
                selectedOption &&
                selectedOption[String(field)] != selectedText
            ) {
                setSelectedText(String(selectedOption[String(field)]));
            }
        }
    }, [value]);

    const handleChange = useCallback(
        (option: DropdownOption) => {
            if (value != option.id) {
                const newValue = !isNaN(option.id)
                    ? parseInt(option.id)
                    : option.id; // converting numeric to integer value

                selectHandler(name, newValue, options);
            }
        },
        [value, selectHandler, options, name]
    );

    const key = useCallback(
        (option: DropdownOption) => {
            if (option.hasOwnProperty('id')) {
                return String(option.id);
            }

            return String(option[String(field)]);
        },
        [field]
    );

    const dropdown = (
        <DropdownContainer
            $autoWidth={autoWidth}
            className={fixedWidth ? 'fixed-width' : ''}>
            <select
                id={`${name}${randomId ? `_${randomId}` : ''}`}
                name={name}
                style={{visibility: 'hidden'}}
                value={value ? value : ''}
                onChange={() => {
                    // do nothing
                }}
                {...props}>
                {options
                    ? options.map((option) => {
                          let id: string | number | boolean = option.id;

                          if (typeof option.id === 'undefined') {
                              id = option[String(field)];
                          }

                          return (
                              <option key={key(option)} value={id}>
                                  {option[String(field)]}
                              </option>
                          );
                      })
                    : null}
            </select>
            <DropdownCBC
                drop="down"
                // NOTE: Disabling check as here as Bootstrap expects filp as boolean
                // but, later complains about getting false instead of 'false'
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                flip="false"
                style={fullWidth ? {width: '100%'} : {}}>
                <DropdownCBC.Toggle
                    data-cy={`${name}${
                        !isNaN(fieldSetIndex) ? `-${fieldSetIndex + 1}` : ''
                    }`}
                    variant={isInvalid ? 'danger' : 'link'}
                    className={name}
                    id={`dropdown-${name}${
                        randomId ? `_${randomId}` : randomString(5)
                    }`}
                    as={DropdownToggleButton}
                    disabled={disabled}>
                    {selectedText === '' ? 'Loading...' : selectedText}
                </DropdownCBC.Toggle>
                <DropdownCBC.Menu as={Menu} data-cy={`${name}-menu`}>
                    {showLoader ? (
                        <DropdownCBC.Item disabled>
                            <Spinner animation="border" size="sm">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </Spinner>
                        </DropdownCBC.Item>
                    ) : null}
                    {options
                        ? options.map((option) => {
                              let id: string | number | boolean = option.id;

                              if (typeof option.id === 'undefined') {
                                  id = option[String(field)];
                              }

                              return (
                                  <DropdownListItem
                                      name={name}
                                      type={type}
                                      active={value == id}
                                      key={key(option)}
                                      disabled={
                                          option.hasOwnProperty('disabled') &&
                                          option.disabled
                                      }
                                      option={option}
                                      onSelect={handleChange}>
                                      {String(option[String(field)])}
                                  </DropdownListItem>
                              );
                          })
                        : null}
                </DropdownCBC.Menu>
            </DropdownCBC>
        </DropdownContainer>
    );

    // I AM A POPOVER
    if (isInvalid && typeof tooltip != null) {
        return (
            <OverlayTrigger
                className="error-popover"
                overlay={tooltip}
                placement="auto-start">
                {dropdown}
            </OverlayTrigger>
        );
    }

    return dropdown;
};

const Toggle = css`
    border: 2px solid rgb(var(--primary_colour)) !important;
    width: 100% !important;
    text-align: left !important;
    color: #6b6f70;
    font-size: 0.85em;
    font-weight: 500;
    text-decoration: none;
    border-radius: 8px;
    background: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 25px !important;
    position: relative;
    height: inherit !important;
`;

const DropdownToggleButton = styled(Button)<{disabled: boolean}>`
    ${Toggle}

    &:hover, &:focus, &:active {
        ${Toggle}
    }

    ${({disabled}) => {
        if (disabled) {
            return `
                background: #e9ecef;
                opacity: 1;
            `;
        }
    }}

    &::after {
        content: '';
        margin-left: 0.255em;
        vertical-align: 0.255em;
        float: right;
        border: solid #6b6f70;
        border-width: 0 2px 2px 0;
        padding: 3px;
        transform: rotate(45deg);
        margin-top: 3px;
        transition: 250ms transform;
        position: absolute;
        top: 7px;
        right: 10px;
    }
`;

const Menu = styled(DropdownMenu)<{'x-placement': string}>`
    right: 0 !important;
    background: #dadedf;
    border: 0;
    box-shadow: 0 0 4px 0 #b9b5b5;
    min-width: initial;
    max-height: 175px;
    overflow: auto;

    .dropdown-item.active,
    .dropdown-item:hover,
    .dropdown-item:focus,
    .dropdown-item:active {
        background: #c5c9ca;
    }

    .dropdown-item {
        font-size: 0.8em;
        font-weight: 500;
        color: #6b6f70;
        padding: 0.01rem 1.5rem;
        white-space: initial;
        line-height: 1em;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    ${(props) => {
        if (props['x-placement'] == 'top-start') {
            return `
                padding: 5px 0 15px;
                border-radius: 8px 8px 0 0;
                bottom: -15px !important;
                z-index: 20;
            `;
        } else if (props['x-placement'] == 'bottom-start') {
            return `
                top: -15px !important;
                padding: 15px 0 5px;
                border-radius: 0 0 8px 8px;
            `;
        }
    }}
`;

const DropdownContainer = styled.div<{$autoWidth?: boolean}>`
    position: relative;

    .dropdown.show {
        .dropdown-toggle::after {
            transform: rotate(-135deg);
            margin-top: 5px;
        }
    }

    .dropdown.show {
        .dropdown-toggle {
            position: relative;
            z-index: 9000;
        }
    }

    .dropdown {
        width: 60%;
        border-radius: 8px;
        margin-top: -23px;
        background: #fff;
        height: calc(1.5em + 0.75rem + 2px);

        @media screen and (max-width: 768px) {
            min-width: 200px;
        }

        ${({$autoWidth}) => {
            if ($autoWidth) {
                return `
                    width: auto;
                `;
            }
        }}
    }

    select {
        border: 0;
        height: 23px;
        width: 0;
    }
`;
