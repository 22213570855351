import React, {
    useCallback,
    useState,
    forwardRef,
    MutableRefObject,
} from 'react';
import {Placement} from 'react-bootstrap/esm/types';
import {Overlay, Tooltip} from 'react-bootstrap';
import {uniqueId} from 'lodash';

export enum OverlayType {
    ToolTip,
    Popover,
}

type OverlayTriggerProps = {
    placement?: Placement;
    overlay: string | JSX.Element | JSX.Element[];
    children: JSX.Element;
    className?: string;
    as?: OverlayType;
};

export const OverlayTrigger = forwardRef<HTMLElement, OverlayTriggerProps>(
    function OverlayTrigger(
        {placement = 'top-end', overlay, children, className = ''},
        ref: MutableRefObject<HTMLElement>
    ) {
        const [show, setShow] = useState(false);

        const target = React.useRef<HTMLElement>(null);

        const onMouseEnter = useCallback(() => {
            setShow(true);
        }, []);

        const onMouseLeave = useCallback(() => {
            setShow(false);
        }, []);

        const onMouseDown = useCallback(() => {
            setShow(false);
        }, []);

        if (typeof overlay === 'undefined' || overlay == '') {
            return children;
        }

        return (
            <>
                {React.Children.map(children, (child) =>
                    React.cloneElement(child, {
                        ref: ref ?? target,
                        onMouseEnter,
                        onMouseLeave,
                        onMouseDown,
                    })
                )}
                <Overlay
                    target={ref ? ref.current : target.current}
                    show={show}
                    placement={placement}>
                    {(props) => (
                        <Tooltip
                            className={className}
                            id={uniqueId('tooltip')}
                            {...props}>
                            {overlay}
                        </Tooltip>
                    )}
                </Overlay>
            </>
        );
    }
);
