import React, {useCallback, useState} from 'react';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface WYSIWYGProps {
    inputText: string;
    setInputText: (html: string) => void;
    testId?: string;
}

const contentStateConverter = (contentState: ContentState): ContentState => {
    // changes block type of images to 'atomic'
    const newBlockMap = contentState.getBlockMap().map((block) => {
        const entityKey = block.getEntityAt(0);
        if (entityKey !== null) {
            const entityBlock = contentState.getEntity(entityKey);
            const entityType = entityBlock.getType();
            switch (entityType) {
                case 'IMAGE': {
                    const newBlock = block.merge({
                        type: 'atomic',
                        text: 'img',
                    });
                    return newBlock;
                }
                default:
                    return block;
            }
        }
        return block;
    });

    // ContentState extends Immutable.Map but we're using low-level methods
    // TODO: Can this be replaced with a method on content state itself?
    return contentState.set('blockMap', newBlockMap) as ContentState;
};

// cleanup function to support pt font-sizes
const convertPtToPx = (inputString = ''): string => {
    // Regex to match 'font-size: Xpt;'
    const ptRegex = /font-size:\s*(\d+)pt;/g;

    // Function to perform the conversion
    return inputString?.replace(ptRegex, (match, pts) => {
        // Calculate the conversion from pt to px
        const pxValue = Math.round(pts * 1.333);
        // Return the new string with px value
        return `font-size: ${pxValue}px;`;
    });
};

export const WYSIWYGEditor = ({
    inputText = '',
    setInputText,
    testId,
}: WYSIWYGProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- entityMap is defined as "any"
    const {contentBlocks, entityMap} = htmlToDraft(convertPtToPx(inputText));
    const contentDataState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
    );
    const editorDataState = EditorState.createWithContent(
        contentStateConverter(contentDataState)
    );

    const [editorState, setEditorState] = useState(editorDataState);

    const onEditorStateChange = useCallback((editorState: EditorState) => {
        setEditorState(editorState);
        setInputText(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
    }, []);

    return (
        <div className="editor pp" data-cy={testId}>
            <Editor
                editorState={editorState}
                wrapperClassName="wyswyg_wrapper_class"
                editorClassName="wyswyg_editor_class"
                toolbarClassName="wyswyg_toolbar_class"
                onEditorStateChange={onEditorStateChange}
            />
        </div>
    );
};
