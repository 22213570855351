import React, {useCallback, useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import styled, {css} from 'styled-components';

interface ModalProps {
    zindex?: number;
}

const CBCModal = styled(Modal)<ModalProps>`
    background: rgba(255, 255, 255, 0.5);
    ${(props) =>
        props.zindex &&
        css`
            z-index: ${props.zindex};
        `}

    .modal-dialog {
        width: 60vw;
        max-width: initial;
        @media (max-width: 700px) {
            width: 100vw;
            margin: 0px !important;
        }
        @media (max-width: 700px) {
            height: 100%;
        }
    }

    .modal-content {
        border-radius: 20px;
        box-shadow: 0px 0px 10px 1px #616161bf;
        border: 0;
        @media (max-width: 700px) {
            height: 100%;
        }
    }
`;

const Header = styled(Modal.Header)`
    background: rgb(var(--primary_colour));
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 10px;

    > div {
        text-align: center;
        width: 100%;
        font-weight: 900;
        color: white;
        font-size: 1em;
    }
`;

const Container = styled.div`
    overflow-x: auto;
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Body = styled(Modal.Body)`
    padding: 0;
    display: flex;
`;

interface CBCDialogInterface {
    display?: boolean;
    title?: string;
    children?: React.ReactNode;
    footer?: React.ReactNode;
    zindex?: number;
}

const CBCDialog = ({
    display,
    title,
    children,
    footer,
    zindex,
}: CBCDialogInterface) => {
    const [show, setShow] = useState(display);

    const handleShow = useCallback(() => {
        setShow(!show);
    }, [show]);

    useEffect(() => {
        setShow((show) => {
            if (show != display) {
                return display;
            }

            return show;
        });
    }, [display]);

    return (
        <CBCModal
            zindex={zindex}
            centered={true}
            show={show}
            onHide={handleShow}
            animation={false}
            backdrop="static">
            <Header>
                <div>{title}</div>
            </Header>
            <Body>
                <Container>{children}</Container>
            </Body>
            {footer}
        </CBCModal>
    );
};

export default CBCDialog;
