import React, {useState} from 'react';
import {Image} from 'react-bootstrap';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {ItemColumn} from 'shared/components/Product/ItemColumn';

export const Category = ({
    category,
    categoryClickHandler,
    isProduct,
}: {
    category: {
        noImage: boolean;
        action: () => void;
        imageUrl: string;
        name: string;
    };
    categoryClickHandler: (category: unknown) => void;
    isProduct: boolean;
}) => {
    const [imageFailed, setImageFailed] = useState(false);
    const [imageLoading, setImageLoading] = useState(true);
    const handleClick = () => {
        if (category.hasOwnProperty('noImage') && category.noImage) {
            category.action();
        } else {
            categoryClickHandler(category);
        }
    };

    const imageContainerStyle = isProduct ? {background: '#fff'} : {};

    return (
        <ItemColumn>
            <div
                className={
                    (category.hasOwnProperty('noImage') && category.noImage) ||
                    imageFailed
                        ? 'hardwareCategory no-image'
                        : 'hardwareCategory'
                }
                onClick={handleClick}>
                <div className="imageContainer" style={imageContainerStyle}>
                    {imageLoading && (
                        <div className="hardwareCategory">
                            <div className="animated-background-for-image" />
                        </div>
                    )}

                    <Image
                        src={`/${category.imageUrl}`}
                        alt={category.name}
                        style={
                            isProduct
                                ? {
                                      objectFit: 'contain',
                                      padding: '5px',
                                  }
                                : {
                                      objectFit: 'cover',
                                  }
                        }
                        onError={() => {
                            setImageFailed(true);
                            setImageLoading(false);
                        }}
                        onLoad={() => {
                            setImageLoading(false);
                        }}
                    />
                </div>
                <div className="categoryName">
                    {parseHtmlString(category.name)}
                </div>
            </div>
        </ItemColumn>
    );
};
