import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useJobContext} from 'contexts';
import {formatPrice} from 'shared/helpers';
import BenchtopVariationCostMoreInfo from './BenchtopVariationCostMoreInfo';
import {PartialJob} from 'shared/types/PartialJob';
import JobExtraVariationCostMoreInfo from 'shared/components/Header/JobExtraVariationCostMoreInfo';
import {useGetJobCabinetVariationDetailsQuery} from 'components/customer/Job/store/jobApi';
import {Spinner} from 'react-bootstrap';

export const VariationCostMoreInfo = () => {
    const {job} = useJobContext() as PartialJob;
    const {data, isUninitialized, isLoading} =
        useGetJobCabinetVariationDetailsQuery(
            {jobId: job.id},
            {skip: !job?.id}
        );

    const getRoomCabNumber = useCallback(
        (roomId: number, roomCabNumber: number) => {
            let roomCab = '';

            const indexOfRoom = job.rooms?.findIndex(
                (room) => room.id == roomId
            );

            if (indexOfRoom !== -1) {
                roomCab = `${indexOfRoom + 1}-${roomCabNumber}`;
            }

            return roomCab;
        },
        [job]
    );

    if (isUninitialized || isLoading) {
        return (
            <Table>
                <tbody>
                    <tr>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </tr>
                </tbody>
            </Table>
        );
    }

    return (
        <>
            <Table>
                <tbody>
                    {data?.map((variation, cabinetKey) => {
                        return (
                            <TableRow
                                key={variation.id}
                                $isEven={cabinetKey % 2 === 0}>
                                <TableCell>
                                    <strong>
                                        {getRoomCabNumber(
                                            variation.room_id,
                                            variation.room_cab_number
                                        )}
                                    </strong>
                                    &nbsp;
                                    <strong>{variation.type_name}</strong>
                                </TableCell>
                                <TableCell style={{color: 'rgb(255, 0, 0)'}}>
                                    {variation.comment}
                                </TableCell>
                                <TableCell $align="right">
                                    {formatPrice(variation.variation_cost, job)}
                                </TableCell>
                            </TableRow>
                        );
                    })}

                    <BenchtopVariationCostMoreInfo jobId={job.id} />
                    <JobExtraVariationCostMoreInfo jobId={job.id} />
                    {job.variationCost ? (
                        <TableRow>
                            <TableCell>
                                <strong>Extra Job Variation Cost:</strong>
                            </TableCell>
                            <TableCell />
                            <TableCell $align="right">
                                {formatPrice(job.variationCost, job)}
                            </TableCell>
                        </TableRow>
                    ) : null}
                </tbody>
            </Table>
            <TotalWrapper>
                <Grow />
                <Total>
                    <strong style={{marginRight: 5}}>TOTAL:</strong>{' '}
                    {formatPrice(job.totalVariationCost, job)}
                </Total>
            </TotalWrapper>
        </>
    );
};

export const Table = styled.table<{$noBorder?: boolean}>`
    width: 100%;
    border-bottom: ${({$noBorder}) =>
        $noBorder ? 'none' : '2px solid #e0e0e0'};
`;

export const TableRow = styled.tr<{$isEven?: boolean}>`
    ${({$isEven}) => ($isEven ? 'background: #f5f5f5;' : '')};
`;

export const TableHead = styled.th<{$align?: string}>`
    padding: 5px 8px;
    text-wrap: wrap;
    text-align: ${({$align}) => ($align ? $align : 'center')};
    font-weight: 600;
    border-bottom: 2px solid #e0e0e0;
`;

export const TableCell = styled.td<{$align?: string}>`
    padding: 5px 8px;
    text-wrap: wrap;
    text-align: ${({$align}) => ($align ? $align : 'left')};
`;

const TotalWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 8px;
`;

const Grow = styled.div`
    flex-grow: 1;
`;

const Total = styled.div`
    text-align: right;
`;
