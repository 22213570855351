import React, {ReactEventHandler, useEffect, useState} from 'react';
import {DefaultTheme, useTheme} from 'styled-components';
import {useFetchSVG} from './helpers/useFetchSVG';

// NOTE: this can be removed later when and if we can make webpack
// respect tsconfig.json as DefaultTheme is already defined and used
// through tsconfig.json
interface MyTheme extends DefaultTheme {
    colors: {
        primary: {
            main: string;
        };
        secondary: {
            main: string;
        };
    };
}

type IconType = {
    iconName: string;
    disabled?: boolean;
    onClick?: ReactEventHandler;
    width?: string;
    style?: React.CSSProperties;
    color?: 'primary' | 'secondary' | string;
    'data-cy'?: string;
    showAltText?: boolean;
};

const Icon = ({
    iconName,
    width,
    color,
    disabled = false,
    onClick,
    style,
    'data-cy': dataCy,
    showAltText = true,
}: IconType) => {
    const theme = useTheme() as MyTheme;
    const [icon, setIcon] = useState<React.ReactElement>(null);
    const {imageData, fetchIcon, imageFetched} = useFetchSVG(
        theme.colors.primary.main,
        theme.colors.secondary.main,
        color
    );

    useEffect(() => {
        if (imageData) {
            setIcon(
                <img
                    src={`${imageData}`}
                    style={disabled ? {...style, opacity: 0} : style}
                    onClick={!disabled && onClick ? onClick : undefined}
                    width={width}
                    data-cy={dataCy}
                />
            );
        }
    }, [imageData, onClick]);

    useEffect(() => {
        if (!imageFetched) {
            if (showAltText) {
                setIcon(<div>Fetching Icon</div>);
            }
            void fetchIcon(iconName, 'MODULE');
        }
    }, [theme, iconName, imageFetched]);

    if (typeof iconName !== 'undefined' && iconName !== '') {
        return icon;
    }
};

export default Icon;
