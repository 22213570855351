/**
 * This component renders main menu item based on configuration set in /src/config/base-config.json.
 *
 * The menu can be nested using "submenu": [] key/value pair within each menu items.
 */
import React, {useState, useEffect, useMemo, useCallback} from 'react';

import {BaseConfig} from 'config';
import {useAppContext} from 'contexts';
import {CBCImage} from 'shared/components/Image';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {Dropdown, ButtonGroup, Button} from 'react-bootstrap';
import {Menu} from 'shared/components/HeaderMenu/Menu';

export const MenuRenderer = () => {
    const {userProfile} = useAppContext();
    const [isMainMenuVisible, setIsMainMenuVisible] = useState(false);
    const isSmallDevice = isDeviceSmall();

    const mainMenu = useMemo(() => {
        if (BaseConfig) {
            let menu = BaseConfig.USER_CUSTOMER.mainMenu;

            if (menu.length) {
                if (
                    userProfile.hasOwnProperty(
                        'manufacturerHelpAndSupportDefaultOptions'
                    ) &&
                    userProfile.manufacturerHelpAndSupportDefaultOptions.length
                ) {
                    const helpAndSupportMenu =
                        userProfile.manufacturerHelpAndSupportDefaultOptions.map(
                            (option) => ({
                                name: `${option.optionName}`,
                                url: `${option.optionUrl}`,
                                icon: 'Help.svg',
                                external: true,
                            })
                        );

                    menu = menu.map((menuItem) => {
                        if (
                            menuItem.hasOwnProperty('id') &&
                            menuItem.id == 'help-and-support' &&
                            menuItem.hasOwnProperty('submenu') &&
                            menuItem.submenu.length == 1
                        ) {
                            menuItem.submenu = [
                                ...menuItem.submenu,
                                ...helpAndSupportMenu,
                            ];
                        }

                        return menuItem;
                    });
                }

                if (!isSmallDevice) {
                    menu = menu.filter(({onlyMobile}) => !onlyMobile);
                }

                return menu;
            }
        }
    }, [userProfile, isSmallDevice]);

    const toggleMainMenu = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        setIsMainMenuVisible((isMainMenuVisible) => !isMainMenuVisible);
    }, []);

    const hideMenu = useCallback((event: MouseEvent) => {
        const mainMenu = (event.target as HTMLElement).closest(
            '.dropdown-toggle'
        );
        const profileButton = (event.target as HTMLElement).closest(
            '.dropdown-toggle-front'
        );
        const withSubMenu = (event.target as HTMLElement).closest('.submenu');
        const logoutMenu = (event.target as HTMLElement).closest(
            '.logout-menu'
        );

        if (!mainMenu && !profileButton && !withSubMenu && !logoutMenu) {
            setIsMainMenuVisible(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('click', hideMenu);

        return () => {
            document.removeEventListener('click', hideMenu);
        };
    }, []);

    return (
        <Dropdown show={isMainMenuVisible} as={ButtonGroup}>
            <Button
                role="mainmenu-toggle"
                variant="success"
                className="dropdown-toggle-front"
                onClick={toggleMainMenu}>
                <CBCImage src="Member-Icon.svg" alt="MemberIcon" />
            </Button>
            <Dropdown.Toggle id="dropdown-basic" onClick={toggleMainMenu}>
                <span>{userProfile.name}</span>
            </Dropdown.Toggle>
            <Menu menu={mainMenu} alignRight={true} />
        </Dropdown>
    );
};
