import React, {useEffect, useMemo} from 'react';
import {Coupon} from 'shared/Coupon';
import {MinimumUsageFee} from 'shared/components/Header/MinimumUsageFee';
import {VariationCost} from 'shared/components/Header/VariationCost';
import {useAppContext, useJobContext, useNotificationContext} from 'contexts';
import {formatPrice, genericMessageHandler} from 'shared/helpers';
import {useGetJobCostQuery} from 'components/customer/Job/store/jobApi';
import {isEmpty} from 'lodash';
import {PricingWebhook} from 'shared/components/Header/PricingWebhook';
import {DateTime} from '@cabinetsbycomputer/datetime';

const SideBarRightFooter = () => {
    const {job} = useJobContext();
    const {userProfile} = useAppContext();
    const {notify} = useNotificationContext();
    const {
        data: cost,
        error: errorCost,
        isLoading,
        isFetching,
        isError,
    } = useGetJobCostQuery(
        {jobId: job.id},
        {
            skip: typeof job?.id === 'undefined',
        }
    );

    useEffect(() => {
        if (isError) {
            let errors: string | string[] = 'Error Calculating Job Cost';

            if ('status' in errorCost) {
                if ('data' in errorCost) {
                    if ('errors' in errorCost.data) {
                        errors = errorCost.data.errors;
                    } else if ('error' in errorCost.data) {
                        errors = errorCost.data.error;
                    }
                } else if ('error' in errorCost) {
                    errors = errorCost.error;
                }
            } else if ('message' in errorCost) {
                errors = errorCost.message;
            }

            if (!Array.isArray(errors)) {
                errors = [errors];
            }

            errors.forEach((error) =>
                genericMessageHandler(notify, {message: error})
            );
        }
    }, [isError, errorCost]);

    if (!userProfile.show_pricing) {
        if (isError) {
            return null;
        } else {
            return (
                <PricingWebhook
                    messages={cost?.pricingMessages}
                    breakdown={cost?.pricingBreakdown}
                    cost={cost?.totalJobCost}
                />
            );
        }
    }

    const hasFreightCost = useMemo(() => {
        return (
            (cost && cost.freightCost > 0) ||
            (cost &&
                cost.couponDetails &&
                cost.couponDetails.discount_free_shipping)
        );
    }, [cost]);

    return (
        <ul className="totals">
            {/* coupon available by manufacturer or job has coupon */}
            {userProfile.isCouponAvailable ||
            (cost && cost.couponDetails && !cost.couponDetails.invalid) ? (
                <li>
                    <Coupon />
                </li>
            ) : null}

            <li>
                <span>
                    Job Subtotal
                    {/** job has coupon and coupon not expired */}
                    {cost &&
                    cost.coupon > 0 &&
                    cost.couponDetails &&
                    (isEmpty(cost.couponDetails.expiry_date) ||
                        (!isEmpty(cost.couponDetails.expiry_date) &&
                            DateTime.now().isBefore(
                                DateTime.parse(
                                    cost.couponDetails.expiry_date.date
                                ).get(),
                                true
                            )))
                        ? ` (Coupon incl.)`
                        : ``}
                    :
                </span>
                <span>
                    {cost && (!isLoading || !isFetching)
                        ? formatPrice(cost.jobSubtotal, cost)
                        : '...'}
                </span>
            </li>

            {cost && cost.minUsage && cost.minUsage.length ? (
                <MinimumUsageFee />
            ) : null}

            {job.totalProductCount > 0 ? (
                <>
                    {
                        // Minimum charge
                        cost && cost.jobMinChargeFromCustMin > 0 ? (
                            <li>
                                <span style={{display: 'flex'}}>
                                    Minimum Charge Fee:
                                </span>
                                <span>
                                    {cost && (!isLoading || !isFetching)
                                        ? formatPrice(
                                              cost.jobMinChargeFromCustMin,
                                              cost
                                          )
                                        : '...'}
                                </span>
                            </li>
                        ) : null
                    }
                    <VariationCost />

                    {hasFreightCost ? (
                        <li>
                            <span>
                                Freight{' '}
                                {cost &&
                                cost.couponDetails?.discount_free_shipping
                                    ? '(includes discount)'
                                    : '(minimum cost)'}
                                :
                            </span>
                            <span>
                                {cost && (!isLoading || !isFetching)
                                    ? formatPrice(cost.freightCost, cost)
                                    : '...'}
                            </span>
                        </li>
                    ) : null}

                    <li>
                        <span>{job.countryTaxRateName}</span>
                        <span>
                            {cost && (!isLoading || !isFetching)
                                ? formatPrice(cost.tax, cost)
                                : '...'}
                        </span>
                    </li>
                </>
            ) : null}
            <li className="cost">
                <span>Total Cost:</span>
                <span>
                    {cost && (!isLoading || !isFetching)
                        ? formatPrice(
                              job.totalProductCount > 0 ? cost.totalJobCost : 0,
                              cost
                          )
                        : '...'}
                </span>
            </li>
            {job.hasOwnProperty('priceExpireDate') &&
            job.priceExpireDate != null ? (
                <li>
                    Pricing is valid until&nbsp;
                    <strong>{job.priceExpireDate}</strong>
                </li>
            ) : null}
        </ul>
    );
};

export default SideBarRightFooter;
