import {useCallback, useState} from 'react';

export const useMouseOver = () => {
    const [isMouseOverComponent, setIsMouseOverComponent] = useState(false);

    const handleMouseEnter = useCallback(() => {
        setIsMouseOverComponent(true);
    }, []);

    const handleMouseExit = useCallback(() => {
        setIsMouseOverComponent(false);
    }, []);

    return {
        isMouseOverComponent,
        handleMouseEnter,
        handleMouseExit,
    };
};
