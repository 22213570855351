import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import useDoorFacesHelper from 'components/customer/Materials/helper/useDoorFacesHelper';
import {useProductContext} from 'contexts';
import {Loader} from 'shared/helpers';
import {MiniRadio} from 'shared/components/Forms/MiniRadio';
import {MoreInfo} from 'shared/components/MoreInfo/MoreInfo';
import styled from 'styled-components';

interface Option {
    value: string;
    label: string;
    checked: boolean;
    image?: string;
    description?: string;
}

interface RadioType {
    name: string;
    value: boolean;
    setFieldValue: (name: string, value: any) => void;
    type?: string;
    disabled?: boolean;
    isLoading?: boolean;
    options?: Option[];
    asRow?: boolean;
}

export const Radio = ({
    name,
    value,
    setFieldValue,
    isLoading,
    options,
    type = 'radio',
    disabled = false,
    asRow = false,
}: RadioType) => {
    const {
        productDataStore,
    }: {
        productDataStore: {
            current: {
                include_drawer_faces: boolean;
            };
        };
    } = useProductContext();
    const [isDisabled, setIsDisabled] = useState(disabled);

    const radioOptions = useMemo(() => {
        if (options) return options;

        return [
            {
                value: '1',
                label: 'Yes',
                checked: value,
            },
            {
                value: '0',
                label: 'No',
                checked: !value,
            },
        ];
    }, [value, options]);

    const handleClick = useCallback(
        (option: Option) => (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();

            let promise = Promise.resolve();
            if (name.includes('include_drawer_faces')) {
                promise = handleDoorFaceRadioChange(option.value == '1');
            }

            setIsDisabled(true);
            setFieldValue(name, option.value == '1');

            promise
                .then(() => {
                    setIsDisabled(false);
                })
                .catch(() => {
                    // console.error('An error occurred');
                    setIsDisabled(false);
                });
        },
        []
    );

    const checkboxHandler = useCallback(() => {
        // using this as react is complaining
    }, []);

    const {handleDoorFaceRadioChange} = useDoorFacesHelper(setFieldValue);

    useEffect(() => {
        if (name.includes('include_drawer_faces')) {
            setFieldValue(
                name,
                productDataStore.current.include_drawer_faces ? true : false
            );
        }
    }, []);

    return (
        <>
            <Loader loader={isLoading || isDisabled} />
            <Row>
                {radioOptions.map((option: Option) => (
                    <Col
                        md={{span: asRow ? 8 : 6}}
                        xs="auto"
                        key={option.value}>
                        <MiniRadio
                            $checked={option.checked}
                            onClick={handleClick(option)}>
                            <Label>
                                {option.image ? (
                                    <Image
                                        thumbnail
                                        src={`/${option.image}`}
                                        alt={option.label}
                                        width="50"
                                        height="50"
                                    />
                                ) : null}
                                {option.label}
                                {option.description ? (
                                    <MoreInfo info={option.description} />
                                ) : null}
                            </Label>
                            <input
                                type={type}
                                name={name}
                                disabled={isDisabled}
                                value={option.value}
                                checked={option.checked}
                                onChange={checkboxHandler}
                            />
                        </MiniRadio>
                    </Col>
                ))}
            </Row>
        </>
    );
};

const Label = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: -25px;
    margin-left: 25px;
    margin-bottom: 20px;
    gap: 15px;
    font-size: 1.5em;
    font-weight: normal;
    color: #2b2b2b;
`;
