import React from 'react';
import styled from 'styled-components';
import {Item, ItemType} from 'shared/components/Product/Item';
import {useItemColumn} from 'shared/components/Product/ItemColumn';
import {CategoryContainer} from 'shared/components/Product/Categories';
import {SettingItem} from 'shared/components/Product/SettingItem';
import {
    DropProps,
    withDropTarget,
} from 'shared/components/DragAndDrop/withDropTarget';

export interface ItemsType extends DropProps<ItemType> {
    items: ItemType[];
    isProduct?: boolean;
    setError?: (errors: string[]) => void;
    noHr?: boolean;
    title?: string;
    loader?: boolean;
    noItemText?: string;
}

export const Items = ({
    items,
    isProduct,
    setError,
    noHr = false,
    title,
    loader,
    noItemText = 'No Items',
    isDnD,
    findItem,
    moveItem,
}: ItemsType) => {
    const {loaders} = useItemColumn(loader);

    return (
        <>
            {!noHr ? <hr /> : null}
            {title ? <h2 className="recently-added-header">{title}</h2> : null}
            <CategoryContainer
                $fixed={items ? items.length < 5 : false}
                className="hardwareItems"
                style={title ? {marginTop: '0'} : {}}>
                {loaders}
                {items && items.length && !loader ? (
                    items.map((item) => {
                        if (isDnD) {
                            return (
                                <SettingItem
                                    key={item.id}
                                    draggable={item}
                                    item={item}
                                    moveItem={moveItem}
                                    findItem={findItem}
                                />
                            );
                        }

                        return (
                            <Item
                                key={item.id}
                                item={item}
                                isProduct={isProduct}
                                setError={setError}
                                loader={loader}
                            />
                        );
                    })
                ) : (
                    <NoItemWrapper>{noItemText}</NoItemWrapper>
                )}
            </CategoryContainer>
        </>
    );
};

export const ItemsDnd = withDropTarget(Items);

const NoItemWrapper = styled.div`
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
`;
