import React, {ForwardedRef, useCallback, useRef} from 'react';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import type {FieldType, TableRowType} from 'shared/types';

export const FieldRender = ({
    cols,
    rowIndex,
    row,
    forwardedRef,
}: {
    cols: FieldType[];
    rowIndex: number;
    row: TableRowType;
    forwardedRef: ForwardedRef<HTMLDivElement>;
}) => {
    const isSmallDevice = isDeviceSmall();
    const refRendered = useRef<boolean>(false);

    const onChangeHandler = useCallback(
        (col: FieldType) => () => {
            col.action(row);
        },
        [row]
    );

    return (
        <>
            {cols
                .filter((col) => {
                    if (
                        isSmallDevice &&
                        typeof col.mobile !== 'undefined' &&
                        !col.mobile
                    ) {
                        return false;
                    }

                    return true;
                })
                .map((col, index) => {
                    let handler;
                    const renderRef = !refRendered.current;
                    refRendered.current = true;

                    if (col.hasOwnProperty('rowAction') && !col.rowAction) {
                        handler = (e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();
                        };
                    }

                    // Hack around requirement that index isn't in the key :shifty:
                    const key = `${col.fieldName}_${index}`;

                    if (col.type == 'radio') {
                        return (
                            <td onClick={handler} key={key}>
                                {renderRef ? <div ref={forwardedRef} /> : null}
                                <input
                                    style={{height: 'initial'}}
                                    type="radio"
                                    name={col.fieldName}
                                    onChange={onChangeHandler(col)}
                                    checked={
                                        col.checked && col.checked == row.id
                                            ? true
                                            : false
                                    }
                                />
                            </td>
                        );
                    } else {
                        let value = row[col.fieldName];
                        const inlineStyle: React.CSSProperties = {};

                        if (typeof col.format !== 'undefined') {
                            value = col.format(
                                value,
                                row,
                                col.fieldName,
                                rowIndex
                            );
                        }

                        if (col.hasOwnProperty('alignLeft') && col.alignLeft) {
                            inlineStyle.textAlign = 'left';
                        }

                        if (col.hasOwnProperty('minWidth') && col.minWidth) {
                            inlineStyle.minWidth = col.minWidth;
                        }

                        return (
                            <td
                                onClick={handler}
                                colSpan={
                                    col.hasOwnProperty('colSpan')
                                        ? col.colSpan
                                        : 1
                                }
                                key={key}
                                style={inlineStyle}>
                                {renderRef ? <div ref={forwardedRef} /> : null}
                                {typeof value !== 'function' ? value : ''}
                            </td>
                        );
                    }
                })}
        </>
    );
};
