import {FormControl, InputGroup} from 'components/customer/Auth/StyledElements';
import React, {useState, useMemo, useCallback} from 'react';
import {getColoredImage} from 'shared/helpers';
import {EyeHidden, EyeVisible} from 'assets';
import styled, {useTheme} from 'styled-components';

type PasswordType = {
    name: string;
    value: string;
    isInvalid: boolean;
    placeholder: string;
    disabled?: boolean;
    id?: string;
    autoComplete?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

export const Password = ({
    name,
    value,
    onChange,
    isInvalid,
    placeholder,
    autoComplete = 'new-password',
    ...props
}: PasswordType) => {
    const theme = useTheme();
    const [newPasswordShown, setNewPasswordShown] = useState<boolean>(false);

    const [eyeHidden, eyeVisible] = useMemo<string[]>(() => {
        const primaryColour = theme.colors.primary.main;
        const eyeHidden = getColoredImage(
            String(EyeHidden),
            '#204380',
            primaryColour
        );

        const eyeVisible = getColoredImage(
            String(EyeVisible),
            '#204380',
            primaryColour
        );

        return [eyeHidden, eyeVisible];
    }, [theme]);

    const handlePasswordVisibility = useCallback(() => {
        setNewPasswordShown((newPasswordShown) => !newPasswordShown);
    }, []);

    return (
        <InputGroup>
            <FormControl
                {...props}
                type={newPasswordShown ? 'text' : 'password'}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                autoComplete={autoComplete}
                className="password-field"
                $isInvalid={isInvalid}
            />
            <InputGroupAppend
                onClick={handlePasswordVisibility}
                className="cbc-input-group-append">
                <img
                    className="cbc-input-group-icon"
                    alt={newPasswordShown ? 'Visible Icon' : 'Hidden Icon'}
                    src={newPasswordShown ? eyeVisible : eyeHidden}
                />
            </InputGroupAppend>
        </InputGroup>
    );
};

const InputGroupAppend = styled(InputGroup.Text)`
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-color: ${({theme}) => theme.colors.primary.main};
    cursor: pointer;
    background-color: #e9ecef;
`;
