import * as formulajs from '@formulajs/formulajs';
import {create, all, isResultSet} from 'mathjs';
import {cloneDeep, transform, merge} from 'lodash';

const createFormula = (action, raw = false) => {
    let formula;

    if (raw) {
        formula = action;
        formula.rawArgs = true;
    } else {
        formula = () => {};
        formula.transform = action;
    }

    return formula;
};

const excel = create(all);

const includeFormulae = [
    'SUM',
    'EXACT',
    'OR',
    'AND',
    'NOT',
    'ROUND',
    'FLOOR',
    'SQRT',
    'MIN',
    'MAX',
    'LEN',
    'IFS',
    'LOWER',
    'TRIM',
    'CONCATENATE',
];
const modifiedFormulae = {};

const evaluateArgument = (arg, scope, isCondition = false) => {
    let value = arg.hasOwnProperty('name') ? arg.name : arg.value;

    if (isCondition) {
        if (typeof value === 'string') {
            value = scope.has(value) ? scope.get(value) : false;
        }

        if (typeof value !== 'boolean' && !isNaN(value)) {
            value = parseInt(value) > 0;
        }
    }

    if (
        arg.type === 'OperatorNode' ||
        arg.type === 'AccessorNode' ||
        arg.type === 'ParenthesisNode' ||
        arg.type === 'FunctionNode' ||
        arg.type === 'SymbolNode'
    ) {
        if (isCondition && arg.type == 'SymbolNode' && !scope.has(arg.name)) {
            // this means any property directly accessed in scope
            // does not exist
            // it is okay to return undefined for true or false case
            // but let it be false in condition
            value = false;
        } else {
            const objectScope = Object.fromEntries(scope);
            value = arg.evaluate(objectScope);
        }
    }

    if (typeof value === 'undefined' && !isCondition) {
        if (scope.has(value)) {
            value = scope.get(value);
        }
    }

    return value;
};

includeFormulae.forEach((formula) => {
    modifiedFormulae[formula] = createFormula((...params) => {
        return formulajs[formula](...params);
    });
});

// NOTE: Manually including original IF as FORMULA_IF
modifiedFormulae['FORMULA_IF'] = createFormula((...params) => {
    return formulajs['IF'](...params);
});

modifiedFormulae.IF = createFormula((args, maths, scope) => {
    if (args.length !== 3) {
        throw new Error('Please pass parameters to IF function');
    }
    const condition = evaluateArgument(args[0], scope, true);

    if (condition) {
        return evaluateArgument(args[1], scope);
    } else {
        return evaluateArgument(args[2], scope);
    }

}, true);

modifiedFormulae.ISNUMBER = createFormula((args: string [], maths, scope: object) => {
    // evaluate the arguments
    const res = args.map(function (arg) {
        return arg.compile().evaluate(scope);
    });

    return res.every((val) => val != null && !isNaN(val));
}, true);

modifiedFormulae.ISUNDEFINED = createFormula((args: string [], maths, scope: object) => {
    // evaluate the arguments
    const res = args.map(function (arg) {
        try {
            return arg.compile().evaluate(scope);
        } catch (error) {
            return undefined;
        }
    });

    return res.every((val) => typeof val === 'undefined');
}, true);

excel.import(modifiedFormulae);

export const simpleNumberTransformer = (
    result: Record<string, unknown>,
    value: string | number,
    key: string
) => {
    if (!isNaN(value) && !isNaN(parseFloat(value))) {
        result[key] = parseFloat(value);
    }

    if (
        value &&
        typeof value === 'object' &&
        typeof value !== 'string' &&
        !Array.isArray(value)
    ) {
        const transformed = transform(value, simpleNumberTransformer);

        if (Object.keys(transformed).length) {
            result[key] = transformed;
        }
    } else if (Array.isArray(value)) {
        result[key] = value.map((v, i) => {
            if (!isNaN(v) && !isNaN(parseFloat(v))) {
                return parseFloat(v);
            }

            if (
                v &&
                typeof v === 'object' &&
                typeof v !== 'string' &&
                !Array.isArray(v)
            ) {
                return transform(v, simpleNumberTransformer);
            }
        });
    }

    return result;
};

const calculate = <T extends string | number | boolean>(
    expression: string,
    scopeOriginal: Record<string, unknown>
): T => {
    if (expression === null) return 0;

    let scope = cloneDeep(scopeOriginal);

    if (typeof expression === 'string') {
        // Remove these later
        expression =
            expression.indexOf('CabLeftWidth') >= 0
                ? expression.replace(
                      new RegExp('CabLeftWidth', 'g'),
                      'cabinet_left_width'
                  )
                : expression;
        expression =
            expression.indexOf('CabRightWidth') >= 0
                ? expression.replace(
                      new RegExp('CabRightWidth', 'g'),
                      'cabinet_right_width'
                  )
                : expression;
        expression =
            expression.indexOf('CabLeftDepth') >= 0
                ? expression.replace(
                      new RegExp('CabLeftDepth', 'g'),
                      'cabinet_left_depth'
                  )
                : expression;
        expression =
            expression.indexOf('CabRightDepth') >= 0
                ? expression.replace(
                      new RegExp('CabRightDepth', 'g'),
                      'cabinet_right_depth'
                  )
                : expression;
        expression =
            expression.indexOf('PartitionHeight') >= 0
                ? expression.replace(
                      new RegExp('PartitionHeight', 'g'),
                      'cabinet_partition_height'
                  )
                : expression; // cabinet_partition_height
        // remove these later

        const parts = expression.split(/(<>|[<>!]?=+)/);

        expression = parts
            .map((s, i) => {
                switch (s) {
                    case '=':
                        return '==';
                    case '<>':
                        return '!=';
                }

                return s;
            })
            .join(' ');
    }

    if (scope.hasOwnProperty('isAdvanced')) {
        if (
            scope.hasOwnProperty('cabinet_door') &&
            !scope.cabinet_door.hasOwnProperty('isAdvanced')
        ) {
            scope.cabinet_door.advanced = scope.isAdvanced;
        } else {
            scope.cabinet_door = {advanced: scope.isAdvanced};
        }
    }

    if (typeof expression === 'string') {
        const transformed = transform(
            cloneDeep(scope),
            simpleNumberTransformer
        );

        scope = merge(scope, transformed);
    }

    const evaluation = excel.evaluate(String(expression), scope) as
        | T
        | {valueOf: () => T[]};

    if (typeof evaluation == 'object') {
        if (isResultSet(evaluation)) {
            return evaluation.valueOf()[0];
        } else {
            return null; // can't happen
        }
    }

    return evaluation;
};

export default {calculate};
