// @flow
import Rectangle from './Rectangle';
import LShape from './LShape';
import Pentagon from './Pentagon';
import KeyShape from './KeyShape';
import UShape from './UShape';
import Ladder from './Ladder';
import Drilling from './Drilling';
import QFP from './QFP';
import Door from './Door';
import Drawer from './Drawer';
import Shelves from './Shelves';
import Shape from './Shape';

export const COMPONENT_TYPES = {
    RECT: 'rect',
    L_SHAPE: 'lShape',
    PENTAGON: 'pentagon',
    KEY_SHAPE: 'keyShape',
    U_SHAPE: 'UShape',
    LADDER: 'Ladder',
    DRILLING: 'Drilling',
    DOOR: 'Door',
    QFP: 'QFP',
    DRAWER: 'Drawer',
    SHELVES: 'Shelves',
};

export const BI_FOLD = {
    LEFT: 'left',
    RIGHT: 'right',
};

/**
 *
 *
 * @class ShapeFactory
 */
class ShapeFactory {
    /**
     *
     *
     * @static
     * @param {string} type
     * @return {*}  {Shape}
     * @memberof ShapeFactory
     */
    static getShape(type: string): Shape {
        switch (type) {
            case COMPONENT_TYPES.L_SHAPE:
                return new LShape();
            case COMPONENT_TYPES.PENTAGON:
                return new Pentagon();
            case COMPONENT_TYPES.KEY_SHAPE:
                return new KeyShape();
            case COMPONENT_TYPES.U_SHAPE:
                return new UShape();
            case COMPONENT_TYPES.LADDER:
                return new Ladder();
            case COMPONENT_TYPES.DRILLING:
                return new Drilling();
            case COMPONENT_TYPES.DOOR:
                return new Door();
            case COMPONENT_TYPES.QFP:
                return new QFP();
            case COMPONENT_TYPES.DRAWER:
                return new Drawer();
            case COMPONENT_TYPES.SHELVES:
                return new Shelves();
            default:
                return new Rectangle();
        }
    }
}

/**
 *
 *
 * @export
 * @class ComponentPainter
 */
export default class ComponentPainter {
    canvasContainer: HTMLElement;
    canvas: HTMLCanvasElement;
    context: CanvasRenderingContext2D;
    width: number;
    height: number;
    options: Object;
    shape: Shape;

    /**
     * Creates an instance of ComponentPainter.
     * @param {string} canvas
     * @param {number} height
     * @param {number} [width=false]
     * @param {boolean} [isQFP=false]
     * @memberof ComponentPainter
     */
    constructor(
        canvas: string,
        height: number,
        width?: number,
        isQFP?: boolean = false
    ) {
        const canvasContainer: HTMLElement | null = document.querySelector(
            `#${canvas}`
        );
        const htmlElement: HTMLElement = document.createElement('CANVAS');

        if (canvasContainer && htmlElement instanceof HTMLCanvasElement) {
            this.canvasContainer = canvasContainer;
            this.canvas = htmlElement;
            this.context = this.canvas.getContext('2d', {
                willReadFrequently: true,
            });

            if (this.canvasContainer) {
                this.width = width
                    ? width
                    : Math.floor(
                          this.canvasContainer.getBoundingClientRect().width
                      );
                this.height = height;

                if (!isQFP) {
                    this.canvasContainer.style.width =
                        this.width > 0 ? `${this.width}px` : 'auto';
                    this.canvasContainer.style.height = `${this.height}px`;
                    this.canvasContainer.innerHTML = `<div class="spinner-border" style="margin: ${
                        height / 2
                    }px auto 0; padding-left: -1rem; padding-top: -1rem;"><span class="visually-hidden">Loading...</span></div>`;
                }

                this.updateCanvasSize();
            }
        }
    }

    /**
     *
     *
     * @param {Object} options
     * @memberof ComponentPainter
     */
    async init(options: Object) {
        this.canvasContainer.innerHTML = '';
        this.canvasContainer.appendChild(this.canvas);

        if (Array.isArray(options)) {
            for (const option of options) {
                let opacity = 1;
                if (option.type == COMPONENT_TYPES.DOOR) {
                    opacity = option.opacity;
                }

                // this.options = option;
                this.shape = ShapeFactory.getShape(option.type);
                this.shape.setOptions(
                    this.canvas,
                    option,
                    this.width,
                    this.height
                );
                await this.shape.draw(opacity);
            }
        } else {
            this.options = options;
            this.shape = ShapeFactory.getShape(options.type);
            this.shape.setOptions(
                this.canvas,
                options,
                this.width,
                this.height
            );
            this.shape.draw();
        }
    }

    /**
     *
     *
     * @memberof ComponentPainter
     */
    updateCanvasSize() {
        this.canvas.setAttribute('width', `${this.width}`);
        this.canvas.setAttribute('height', `${this.height}`);

        this.canvas.style.width = `${this.width}px`;
        this.canvas.style.height = `${this.height}px`;
        this.canvas.style.objectFit = 'contain';
    }
}
