import {useAppContext} from 'contexts';
import {ReactNode} from 'react';
import {useConfirmationDialog} from 'shared/components/ConfirmationDialog/ConfirmationDialog';

type ContactUsPopupType = {
    showContactInfo: () => void;
    dialog: ReactNode;
};

export const useContactUsPopup = (): ContactUsPopupType => {
    const {dialog, showDialog} = useConfirmationDialog('', '', true);
    const {userProfile} = useAppContext();

    const showContactInfo = () => {
        showDialog({
            title: 'Manufacturer Contact',
            message: userProfile.manufacturerContactInfo,
            hideYesButton: true,
            hideNoButton: true,
            keyboard: false,
            buttons: [
                {
                    name: 'Ok',
                    show: true,
                },
            ],
        });
    };

    return {
        showContactInfo,
        dialog,
    };
};
