import React, {useCallback, useRef, useState} from 'react';
import {Button, Overlay, Tooltip} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

interface ButtonWithPopoverProps {
    handleShow: (event: React.MouseEvent) => void;
    minUsage: boolean;
    dynamicposition: boolean;
    disabled: boolean;
    tooltipText: string;
}

export const ButtonWithPopover = ({
    handleShow,
    minUsage,
    dynamicposition,
    disabled,
    tooltipText,
}: ButtonWithPopoverProps) => {
    const isSmallDevice = isDeviceSmall();
    const target = useRef();
    const [show, setShow] = useState(false);

    const handleMouseOver = useCallback(() => {
        setShow(true);
    }, []);

    const handleMouseOut = useCallback(() => {
        setShow(false);
    }, []);

    return (
        <>
            <Button
                ref={target}
                tabIndex={-1}
                variant="link"
                onClick={handleShow}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                style={
                    minUsage
                        ? {width: '20px', margin: '0 0 0 10px'}
                        : dynamicposition
                        ? {width: '20px', margin: '-8px 0 0 5px'}
                        : {}
                }
                disabled={disabled}>
                <Icon
                    style={{
                        verticalAlign: minUsage ? 'baseline' : 'middle',
                    }}
                    iconName="More-Info.svg"
                />
            </Button>
            <Overlay
                show={show}
                target={target.current}
                placement={isSmallDevice ? 'top' : 'right'}>
                {(props) => <Tooltip {...props}>{tooltipText}</Tooltip>}
            </Overlay>
        </>
    );
};
