import React, {useCallback} from 'react';
import {useJobContext} from 'contexts';
import {useNavigate} from 'react-router-dom';
import {PartialJob} from 'shared/types/PartialJob';
import {useConfirmationDialog} from 'shared/components/ConfirmationDialog/ConfirmationDialog';
import {CBCButton} from 'shared/helpers';

interface SaveButtonProps {
    disabled?: boolean;
    label?: string;
    title?: string;
    message?: string | JSX.Element;

    action?: () => Promise<void> | void;

    anotherTopLabel?: string;
    anotherTopAction?: () => Promise<void> | void;

    continueLabel?: string;
    continueAction?: () => Promise<void> | void;

    goToDashboardLabel?: string;
    beforeRedirectAction?: () => Promise<void> | void;

    noDialog?: boolean;
}

export const SaveButton = ({
    disabled,
    label = 'Save',
    title = 'Saved',
    message = 'Successfully saved',

    action,

    anotherTopLabel = 'Add Another Top',
    anotherTopAction,

    continueLabel = 'Continue Editing',
    continueAction,

    goToDashboardLabel = 'Go to Dashboard',
    beforeRedirectAction,

    noDialog = false,
}: SaveButtonProps) => {
    const {job, room} = useJobContext() as PartialJob;
    const navigate = useNavigate();
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();

    const redirect = useCallback(() => {
        const {displayId: jobId} = job;
        const {id: roomId} = room;
        let url = '/v2';

        if (jobId && roomId) {
            url = `/v2/job/${jobId}/room/${roomId}/dashboard`;
        } else if (jobId && typeof roomId === 'undefined') {
            url = `/v2/job/${jobId}/dashboard`;
        }

        navigate(url);
    }, [job, room]);

    const handleCancelClick = useCallback(() => {
        const processAction = () => {
            if (typeof action === 'function') {
                return Promise.resolve(action());
            }

            return Promise.resolve();
        };

        processAction()
            .then(() => {
                if (noDialog) {
                    redirect();
                    return;
                }

                showDialog({
                    title,
                    message,
                    hideYesButton: true,
                    hideNoButton: true,
                    buttons: [
                        {
                            show: typeof anotherTopAction === 'function',
                            controlledHideDialog: true,
                            name: anotherTopLabel,
                            alignLeft: true,
                            iconName: 'Add-Benchtop.svg',
                            action: async () => {
                                if (typeof anotherTopAction === 'function') {
                                    await anotherTopAction();
                                }
                            },
                        },
                        {
                            show: true,
                            controlledHideDialog: true,
                            name: continueLabel,
                            action: async () => {
                                if (typeof continueAction === 'function') {
                                    await continueAction();
                                }

                                hideDialog();
                            },
                        },
                        {
                            show: true,
                            controlledHideDialog: true,
                            name: goToDashboardLabel,
                            variant: 'primary-color',
                            action: async () => {
                                if (
                                    typeof beforeRedirectAction === 'function'
                                ) {
                                    await beforeRedirectAction();
                                }

                                redirect();
                            },
                        },
                    ],
                });
            })
            .catch(() => {
                // handle error here
            });
    }, [
        job,
        room,
        title,
        message,
        continueLabel,
        goToDashboardLabel,
        noDialog,
        action,
        continueAction,
        beforeRedirectAction,
        redirect,
        anotherTopLabel,
        anotherTopAction,
    ]);

    return (
        <>
            <CBCButton
                disabled={disabled}
                style={{width: '100%'}}
                type="button"
                onClick={handleCancelClick}
                iconName="Button-Tick.svg"
                className="job-button button-blue">
                {label}
            </CBCButton>
            {dialog}
        </>
    );
};
