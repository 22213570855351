import {useCallback, useEffect, useMemo, useState} from 'react';
import {useComponentMountedHelper} from 'shared/helpers';
import {useParams, useSearchParams} from 'react-router-dom';
import {useNavigate} from 'shared/reload/helper/useNavigate';
import {useProductContext, useJobContext} from 'contexts';
import {RECENTLY_ADDED_KEYS} from 'hooks';
import {PartialRoom} from 'shared/types/PartialRoom';
import {ItemType} from 'shared/components/Product/Item';

export const useItem = (
    item: ItemType,
    isProduct: boolean,
    setError: (errors: string[]) => void
) => {
    const {jobId, roomId} = useParams();
    const [searchParams] = useSearchParams();
    const category = searchParams.get('category');
    const subCategory = searchParams.get('subCategory');
    const favourites = searchParams.get('favourites');

    const {
        AddToCart: addToCart,
        AddToFavourite: addToFavourite,
        addRecentItem,
    } = useProductContext<{
        AddToCart: (item: ItemType, count: number) => Promise<void>;
        AddToFavourite: (
            item: ItemType,
            favourite: boolean,
            isProduct: boolean
        ) => Promise<void>;
        addRecentItem: (id: number, key: string) => void;
    }>();
    const {room} = useJobContext() as {room: PartialRoom};
    const {isMounted} = useComponentMountedHelper();

    const [isFavourite, setIsFavourite] = useState(item.favourites > 0);
    const [count, setCount] = useState(1);
    const [favouriteButtonDisabled, setFavouriteButtonDisabled] =
        useState(false);
    const [cartButtonDisabled, setCartButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const increment = useCallback(() => {
        setCount((count) => count + 1);
    }, []);

    const decrement = useCallback(() => {
        setCount((count) => (count > 0 ? count - 1 : count));
    }, []);

    const addToFavouriteHandler = useCallback(
        async (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            setLoading(true);

            setFavouriteButtonDisabled(true);
            await addToFavourite(item, !isFavourite, isProduct);

            setIsFavourite((isFavourite) => !isFavourite);
            setFavouriteButtonDisabled(false);
            setLoading(false);
        },
        [setFavouriteButtonDisabled, addToFavourite]
    );

    const addToCartHandler = useCallback(() => {
        if (setError && count < 1) {
            setError(['Quantity must be greater than 0']);
            return;
        }
        setCartButtonDisabled(true);
        addToCart(item, count)
            .then(() => {
                addRecentItem(item.id, RECENTLY_ADDED_KEYS.HARDWARE);
                isMounted.current && setCartButtonDisabled(false);
            })
            .catch(() => {
                // handle stuffs here
            });
    }, [addToCart, addRecentItem, setError, count, item, isMounted]);

    const hardwareLinkFormatter = useCallback(() => {
        const params: {
            category?: string;
            subCategory?: string;
            favourites?: string;
            product?: string;
            sundry?: string;
        } = {};

        if (category) params.category = category;
        if (subCategory) params.subCategory = subCategory;
        if (favourites) params.favourites = '1';

        if (item.id && isProduct) params.product = String(item.id);
        else params.sundry = String(item.id);

        if (params.hasOwnProperty('sundry')) {
            // get category from sundry item
            params.category = String(item.sundryCategoryId);
        }

        const query = new URLSearchParams(params).toString();

        if (isProduct) {
            return `/v2/job/${jobId}/room/${roomId}/product?${query}`;
        }

        return `/v2/job/${jobId}/hardware?${query}`;
    }, [category, subCategory, favourites, item, isProduct, jobId, roomId]);

    const itemClickHandler = useCallback(() => {
        navigate(hardwareLinkFormatter());
    }, [navigate, hardwareLinkFormatter]);

    const countChangeHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCount(parseInt(event.currentTarget.value));
        },
        []
    );

    useEffect(() => {
        if (setError && count > 0) {
            setError([]);
        }
    }, [count]);

    useEffect(() => {
        setCartButtonDisabled(false);
        if (item.hasOwnProperty('stockStatus') && item.stockStatus == 1) {
            setCartButtonDisabled(true);
        }
    }, [item]);

    const style = useMemo(() => {
        return isProduct
            ? {
                  backgroundImage: `url("/uploads/gocabinet_materials/${
                      room && room.extMaterial && room.extMaterial.image != ''
                          ? room.extMaterial.image
                          : 'default_exterior.jpg'
                  }")`,
              }
            : {};
    }, [isProduct, room]);

    return {
        loading,
        isFavourite,
        style,
        favouriteButtonDisabled,
        cartButtonDisabled,
        count,
        increment,
        decrement,
        itemClickHandler,
        addToFavouriteHandler,
        countChangeHandler,
        hardwareLinkFormatter,
        addToCartHandler,
    };
};
