// @flow
import React, {useEffect, useRef, useState, useMemo} from 'react';

import {InputGroup, Button, Form} from 'react-bootstrap';
import {useFormikContext} from 'formik';
import {
    useNotificationContext,
    useProductContext,
    useAppContext,
} from 'contexts';
import {OverlayTrigger} from 'shared';
import {ChevronDown, ChevronUp} from 'assets';
import {validationErrorAppliesToField} from 'shared/validator';
import styled from 'styled-components';

const MouseEvent = {
    NONE: 'none',
    INCREASE: 'increase',
    DECREASE: 'decrease',
};

type NumberType = {
    name: string,
    value: any,
    selectHandler: (name: string, value: any) => void,
    hasFormik?: boolean,
    noSpinners?: boolean,
    increaseIcon?: React$Element<any> | string,
    decreaseIcon?: React$Element<any> | string,
    reverseIcons?: boolean,
    step?: number,
    cycle?: boolean,
    showDialog: () => void,
    hideDialog: () => void,
    setCurrentTab: () => void,
    formStructure: {},
    randomId: string,
    fieldSetIndex?: number,
    max?: number,
    min?: number,
    isInvalid?: boolean,
    isQFP?: boolean,
    in_sizes_tab?: boolean,
    ...
};

export const Number = ({
    name,
    value,
    selectHandler,
    hasFormik = true,
    noSpinners = false,
    increaseIcon = ChevronUp,
    decreaseIcon = ChevronDown,
    reverseIcons = false,
    step = 1,
    cycle = false,
    showDialog,
    hideDialog,
    setCurrentTab,
    formStructure,
    randomId,
    fieldSetIndex,
    max,
    min,
    isQFP = false,
    isInvalid = false,
    fieldIndex,
    in_sizes_tab,
    inSpecsTab,
    fieldSetGroupName,
    ...otherParams
}: NumberType): React$Element<any> => {
    const formik = hasFormik ? useFormikContext() : undefined;

    const [localValue, setLocalValue] = useState(value);
    const [mouseHoldEvent, setMouseHoldEvent] = useState(MouseEvent.NONE);
    const {messages} = useNotificationContext();
    const productSpecificData = useProductContext();
    const {smallLayout} = useAppContext();

    const toolTip = useMemo(() => {
        let errorMessages = messages.errors;

        if (
            productSpecificData &&
            productSpecificData.hasOwnProperty('fieldIndex') &&
            productSpecificData.fieldIndex > -1 &&
            messages.errors.hasOwnProperty(productSpecificData.fieldIndex)
        ) {
            errorMessages = messages.errors[productSpecificData.fieldIndex];
        }

        if (
            errorMessages.length &&
            hasFormik &&
            formik?.errors &&
            ['door_hinge_amount_1'].includes(name)
        ) {
            errorMessages = errorMessages?.filter((e) =>
                e.fields?.some((f) => Object.keys(formik.errors).includes(f))
            );
        }

        if (isInvalid && errorMessages.length) {
            const tooltips = messages.errors
                .filter((error) =>
                    validationErrorAppliesToField(error, name, fieldSetIndex)
                )
                .map((error) => error.message);

            if (tooltips.length) {
                if (smallLayout) {
                    tooltips[0] = `* ${tooltips[0]}`;
                    return tooltips.join('\n* ');
                } else {
                    return (
                        <ul>
                            {tooltips.map((tooltip, index) => {
                                return <li key={index}>{tooltip}</li>;
                            })}
                        </ul>
                    );
                }
            }
        }
    }, [isInvalid, messages, smallLayout]);

    const intervalRef = useRef();
    const timeoutRef = useRef();

    const updateValue = (name, latestValue) => {
        if (value != latestValue) {
            if (typeof selectHandler !== 'undefined' && selectHandler)
                selectHandler(name, latestValue);
            else formik && formik.setFieldValue(name, latestValue);
        }
    };

    const updateLocalValue = useRef((mouseEvent) => {
        timeoutRef.current = window.setTimeout(() => {
            window.clearInterval(intervalRef.current);
            intervalRef.current = window.setInterval(() => {
                if (mouseEvent == MouseEvent.INCREASE) {
                    setLocalValue((currentValue) => {
                        if (
                            (typeof max !== 'undefined' &&
                                currentValue < max) ||
                            typeof max === 'undefined'
                        )
                            return currentValue + 1;
                        else return currentValue;
                    });
                } else if (mouseEvent == MouseEvent.DECREASE) {
                    setLocalValue((currentValue) => {
                        if (
                            (typeof min !== 'undefined' &&
                                currentValue > min) ||
                            typeof min === 'undefined'
                        )
                            return currentValue - 1;
                        else return currentValue;
                    });
                }
            }, [100]);
        }, 800);
    }).current;

    useEffect(() => {
        value != localValue && setLocalValue(value);
    }, [value]);

    useEffect(() => {
        if (mouseHoldEvent && mouseHoldEvent != MouseEvent.NONE) {
            updateLocalValue(mouseHoldEvent);
        } else {
            window.clearTimeout(timeoutRef.current);
            window.clearInterval(intervalRef.current);
            value != localValue && updateValue(name, localValue);
        }
    }, [mouseHoldEvent]);

    const getCorrectValue = (number) => {
        number = parseFloat(number);
        number = number % 1 === 0 ? parseInt(number) : number;

        return number;
    };

    const focusInput = (e) => {
        e.target.closest('.cbc-number').querySelector('input').focus();
    };

    const increase = (e) => {
        if (
            (typeof max !== 'undefined' && localValue < max) ||
            typeof max === 'undefined'
        ) {
            const newValue = getCorrectValue(localValue) + step;
            setLocalValue(newValue);
            updateValue(name, newValue);
        }

        focusInput(e);
    };

    const decrease = (e) => {
        if (
            (typeof min !== 'undefined' && localValue > min) ||
            typeof min === 'undefined'
        ) {
            const newValue = getCorrectValue(localValue) - step;
            setLocalValue(newValue);
            updateValue(name, newValue);
        }

        focusInput(e);
    };

    const handleBlurCustom = (e) => {
        formik && formik.handleBlur(e);

        value != localValue && updateValue(name, localValue);
    };

    const enterKeyPressed = (event) => {
        if (event.keyCode === 13) {
            handleBlurCustom(event);
            formik && formik.submitForm();
        }
    };

    const handleChange = (e) => {
        const newValue = parseInt(e.target.value);

        if (e.target.value == '') {
            setLocalValue(min || 0);
            return;
        }

        if (max && newValue > max) {
            return;
        }

        if (min && newValue < min) {
            return;
        }

        setLocalValue(newValue);
    };

    let formControl = (
        <NumberInputGroup
            $inline={reverseIcons}
            className="cbc-number"
            style={{width: in_sizes_tab ? '150px' : 'auto'}}>
            <Form.Control
                {...otherParams}
                type="number"
                onKeyUp={enterKeyPressed}
                name={name}
                value={localValue}
                onChange={handleChange}
                onBlur={handleBlurCustom}
                isInvalid={isInvalid}
                data-cy={`${name}-${fieldIndex + 1}`}
                min={typeof min != 'undefined' ? min : undefined}
                max={typeof max != 'undefined' ? max : undefined}
                inputMode="numeric"
            />
            <InputGroupAppend
                $inline={reverseIcons}
                className={reverseIcons ? 'flex-row-reverse' : ''}>
                <InputButton
                    $inline={reverseIcons}
                    tabIndex="-1"
                    variant="outline-secondary"
                    className="number-spinner"
                    onClick={increase}
                    data-cy={`${name}-increase-${fieldIndex + 1}`}
                    onMouseDown={() => setMouseHoldEvent(MouseEvent.INCREASE)}
                    onMouseUp={() => setMouseHoldEvent(MouseEvent.NONE)}>
                    {typeof increaseIcon === 'string' ? (
                        <img
                            style={{width: '52%'}}
                            src={`data:image/svg+xml;base64, ${btoa(
                                increaseIcon
                            )}`}
                            alt="Increase number"
                        />
                    ) : (
                        increaseIcon
                    )}
                </InputButton>
                <InputButton
                    $inline={reverseIcons}
                    tabIndex="-1"
                    variant="outline-secondary"
                    className="number-spinner"
                    onClick={decrease}
                    data-cy={`${name}-decrease-${fieldIndex + 1}`}
                    onMouseDown={() => setMouseHoldEvent(MouseEvent.DECREASE)}
                    onMouseUp={() => setMouseHoldEvent(MouseEvent.NONE)}>
                    {typeof decreaseIcon === 'string' ? (
                        <img
                            style={{width: '52%'}}
                            src={`data:image/svg+xml;base64, ${btoa(
                                decreaseIcon
                            )}`}
                            alt="Decrease number"
                        />
                    ) : (
                        decreaseIcon
                    )}
                </InputButton>
            </InputGroupAppend>
        </NumberInputGroup>
    );

    if (noSpinners) {
        formControl = (
            <Form.Control
                {...otherParams}
                type="number"
                name={name}
                value={localValue}
                onChange={handleChange}
                onBlur={handleBlurCustom}
                onKeyUp={enterKeyPressed}
                isInvalid={isInvalid}
                data-cy={`${name}-${fieldIndex + 1}`}
                min={typeof min != 'undefined' ? min : undefined}
                max={typeof max != 'undefined' ? max : undefined}
            />
        );
    }

    // I AM A POPOVER
    if (isInvalid && typeof toolTip !== 'undefined' && toolTip != '') {
        return (
            <OverlayTrigger
                className="error-popover"
                as="popover"
                trigger={['focus', 'hover']}
                overlay={toolTip}
                placement={isQFP ? 'auto-start' : 'right-end'}>
                {formControl}
            </OverlayTrigger>
        );
    }

    return formControl;
};

const NumberInputGroup = styled(InputGroup)`
    max-height: 38px;
    width: 60%;
    background: white !important;

    > input {
        border-right: 0 !important;
    }

    ${({$inline}) => {
        if ($inline) {
            return `        
            flex-wrap: nowrap;
            align-items: center;

            :hover,
            :active,
            :focus {
                > div:last-child {
                    border: 1px solid #b5b5b5;
                }
            }`;
        }
    }}
`;

const NumberInput = styled(Form.Control)`
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0;
    height: inherit !important;
`;

const InputButton = styled(Button)`
    height: 50% !important;
    width: 30px !important;
    box-sizing: border-box;
    padding: 0 5px !important;
    border-left: 1px solid gray !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-left: 5px;
    border: 0;
    border-radius: 0;
    border-color: #ccc;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: white;

    &:first-child {
        position: relative;
        z-index: 0;
        margin-top: 5px !important;
    }

    &:last-child {
        position: relative;
        z-index: 0;
        margin-bottom: 5px !important;
    }

    &:hover,
    &:active,
    &:focus {
        background: white !important;
    }

    ${({$inline}) => {
        if ($inline) {
            return `
            padding: 0 !important;
            margin: 0 !important;
            border: 0 !important;
            
            &:first-child {
                margin-top: 0 !important;
            }

            &:last-child {
                margin-bottom: 0 !important;
            }

            img {
                width: 25px;
            }
            `;
        }
    }}
`;

const InputGroupAppend = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: inherit !important;
    border: 2px solid ${({theme}) => theme.colors.primary.main};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 0;

    ${({$inline}) => {
        if ($inline) {
            return `
            height: 25px !important;
            align-items: center;
            border: 1px solid transparent;
            transition: border-color 0.15s ease-in-out;
            `;
        }
    }}
`;
