import {useAppContext} from 'contexts';
import React, {useCallback} from 'react';
import {Button, Modal, Spinner} from 'react-bootstrap';
import {WYSIWYGEditor} from 'shared/WYSIWYG';
import {Icon} from 'shared/helpers';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {
    MoreInfoScope,
    useMoreInfo,
} from 'shared/components/MoreInfo/helpers/useMoreInfo';

interface MoreInfoModalProps {
    handleClose: () => void;
    htmlClassName: string;
    dialogClassName: string;
    editbuttonVisible: boolean;
    info: number | string;
    tooltipText: string;
    scope?: MoreInfoScope;
}

export const MoreInfoModal = ({
    handleClose,
    tooltipText,
    htmlClassName,
    dialogClassName,
    editbuttonVisible,
    info,
    scope,
}: MoreInfoModalProps) => {
    const {userProfile} = useAppContext();
    const {
        infoText,
        loading,
        setNewInfoOnSave,
        saveMoreInfo,
        editInfo,
        setEditInfo,
    } = useMoreInfo(info, scope, userProfile, handleClose);

    const handleEditButtonClick = useCallback(() => setEditInfo(true), []);

    return (
        <Modal
            show={true}
            onHide={handleClose}
            className={htmlClassName}
            dialogClassName={dialogClassName}
            centered>
            <Modal.Header>
                <div className="modal-title h4">{tooltipText}</div>
                {userProfile?.isAllowedToEditInfoText &&
                editbuttonVisible &&
                typeof info === 'number' ? (
                    <Button
                        variant="link"
                        className="mobile-lone-button"
                        style={{padding: '0'}}
                        onClick={handleEditButtonClick}>
                        <Icon
                            iconName="Options-Edit.svg"
                            style={{height: '25px'}}
                        />
                    </Button>
                ) : null}
            </Modal.Header>
            <div className="modal-body">
                {editInfo ? (
                    <WYSIWYGEditor
                        inputText={infoText}
                        setInputText={setNewInfoOnSave}
                    />
                ) : (
                    <section style={{minHeight: '33px'}}>
                        {loading ? (
                            <section style={{textAlign: 'center'}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </Spinner>
                            </section>
                        ) : (
                            parseHtmlString(infoText)
                        )}
                    </section>
                )}
            </div>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    {editInfo ? 'Cancel' : 'Close'}
                </Button>
                {editInfo ? (
                    <Button variant="success" onClick={saveMoreInfo}>
                        Save
                    </Button>
                ) : null}
            </Modal.Footer>
        </Modal>
    );
};
