import {Field} from 'formik';
import styled, {DefaultTheme} from 'styled-components';

export const InputField = styled(Field)`
    &,
    &[type='number'] {
        border-width: 2px;
        border-radius: 8px;
        border-color: ${({theme}: {theme: DefaultTheme}) =>
            theme.colors.primary.main} !important;
        width: 100%;
        height: 38px;
        padding: 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        box-shadow: none !important;
        &:focus {
            background: #fff !important;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
        }
        &[disabled] {
            background: #ddd !important;
        }
        ${({$invalid}: {$invalid: boolean}) => {
            if ($invalid) {
                return `background: #f4cfc0 !important;`;
            }
            return `background: #fff !important;`;
        }}
        ${({component}) => {
            if (component == 'textarea') {
                return `
                    resize: none;
                    &[disabled] {
                        background-color: #ddd !important;
                    }
                `;
            }
        }};
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type='number'] {
        -moz-appearance: textfield;
    }
    &::placeholder {
        color: hsl(0, 0%, 50%);
    }
`;

export const FilterTextField = styled(Field)`
    height: 30px;
    padding: 8px;
    border-style: solid;
    border-width: 1px;
    border-raidus: 0;
    border-color: #999 #bbb #ddd;
    box-shadow: none !important;
    outline: none;
    &:focus {
        background: #fff !important;
        box-shadow: none !important;
    }
    &::placeholder {
        color: hsl(0, 0%, 50%);
    }
    width: ${({$width}: {$width: string}) => $width};
`;

export const InputGroup = styled.div<{
    $required?: boolean;
    $labelWidth?: string;
}>`
    display: flex;
    align-items: center;
    gap: 10px;
    & > label {
        font-size: 0.85rem;
        font-weight: bold;
        min-width: ${({$labelWidth = '170px'}) => $labelWidth};
        max-width: ${({$labelWidth = '170px'}) => $labelWidth};
        color: ${({theme}) => theme.colors.primary.main} !important;
        &:after {
            ${({$required}) =>
                $required
                    ? `
                content: '*';
                color: red;`
                    : ''}
        }
    }
    & p {
        font-size: 0.85rem;
        color: #495057;
    }
`;

export const InputGroupVert = styled.div`
    display: flex;
    flex-direction: column;
    & > label {
        font-size: 0.85rem;
        font-weight: bold;
        color: ${({theme}) => theme.colors.primary.main} !important;
    }
    & > strong {
        margin-bottom: 5px;
    }
`;

interface FlexProps {
    $flexDirection?: string;
    $alignItems?: string;
    $justifyContent?: string;
    $gap?: string;
    $padding?: string;
    $margin?: string;
    $width?: string;
    $height?: string;
    $maxHeight?: string;
    $flexWrap?: string;
}
export const Flex = styled.div<FlexProps>`
    display: flex;
    flex-direction: ${({$flexDirection}) => $flexDirection};
    align-items: ${({$alignItems}) => $alignItems};
    justify-content: ${({$justifyContent}) => $justifyContent};
    gap: ${({$gap}) => $gap};
    padding: ${({$padding}) => $padding};
    margin: ${({$margin}) => $margin};
    width: ${({$width}) => $width};
    height: ${({$height}) => $height};
    max-height: ${({$maxHeight}) => $maxHeight};
    flex-wrap: ${({$flexWrap}) => $flexWrap};
`;

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #c92434;
    gap: 5px;
    border-radius: 40px;
    padding: 10px 30px;
    margin-bottom: 15px;
    & > p {
        color: white;
        font-weight: bold;
        font-size: 0.85rem;
    }
`;

export const StyledHeader = styled.div`
    font-weight: 700;
    background: ${({theme}) => theme.colors.primary.main};
    padding: 8px 10px;
    color: #fff;
    border-radius: 24px;
    border-bottom-right-radius: 0;
    font-size: 1.3em;
    text-align: center;
    position: relative;
`;

export const SectionLabel = styled.label`
    font-size: 1rem;
    font-weight: bold;
    color: ${({theme}) => theme.colors.primary.main};
`;

export const SectionHeader = styled.h1`
    color: ${({theme}) => theme.colors.secondary.main};
`;

export const HorizontalLine = styled.hr`
    background-color: ${({theme}) => theme.colors.secondary.main};
    border: none;
    height: 2px;
    margin-top: 4px;
`;
