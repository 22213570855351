import React, {useMemo} from 'react';
import styled from 'styled-components';

export const ItemColumn = styled.div<{
    $hardware?: boolean;
}>`
    .details {
        height: ${({$hardware}) => ($hardware ? '170px' : '135px')};
    }
`;

export const useItemColumn = (loader: boolean) => {
    const loaders = useMemo(() => {
        if (loader) {
            return (
                <>
                    <ItemColumn>
                        <div className="hardwareItem">
                            <div className="animated-background" />
                        </div>
                    </ItemColumn>
                    <ItemColumn>
                        <div className="hardwareItem">
                            <div className="animated-background" />
                        </div>
                    </ItemColumn>
                    <ItemColumn>
                        <div className="hardwareItem">
                            <div className="animated-background" />
                        </div>
                    </ItemColumn>
                </>
            );
        }
    }, [loader]);

    return {
        loaders,
    };
};
