import React from 'react';
import {formatPrice} from 'shared/helpers';
import {MinimumUsage} from 'components/customer/Job/store/jobApi';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {Table, TableCell, TableRow, TableHead} from './VariationCostMoreInfo';

const MinimumUsageMoreInfo = ({
    minimumUsages,
    mapMaterialDisplayName,
}: {
    minimumUsages: MinimumUsage[];
    mapMaterialDisplayName: (materialId: number) => string;
}) => {
    const groupByMaterial = (
        usages: MinimumUsage[]
    ): {
        materials: MinimumUsage[];
        edges: MinimumUsage[];
    } => {
        return usages.reduce(
            (acc, cur) => {
                if (cur.edgeId) {
                    acc.edges.push(cur);
                } else {
                    acc.materials.push(cur);
                }
                return acc;
            },
            {materials: [], edges: []}
        );
    };

    const {materials, edges} = groupByMaterial(minimumUsages);

    const renderUsages = (usages: MinimumUsage[], header: string) => (
        <>
            <TableRow>
                <TableCell
                    colSpan={6}
                    style={{
                        textAlign: 'left',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    }}>
                    {header}:
                </TableCell>
            </TableRow>
            {usages.map((minUsage, index) => {
                const display = displayName(minUsage);
                return (
                    <TableRow key={minUsage.id} $isEven={index % 2 === 0}>
                        <TableCell>
                            {display
                                ? parseHtmlString(display)
                                : minUsage.materialType}
                        </TableCell>
                        <TableCell $align="center">
                            {parseFloat(
                                minUsage.minimumUsage?.toString()
                            ).toFixed(2)}
                            {minUsage.measurement === 'LM' ? (
                                'l/m'
                            ) : (
                                <>
                                    m<sup>2</sup>
                                </>
                            )}
                        </TableCell>
                        <TableCell $align="center">
                            {minUsage.calculatedSheet > 0
                                ? `${minUsage.calculatedSheet} sheet(s) + `
                                : ''}
                            {parseFloat(minUsage.usage?.toString()).toFixed(2)}
                            {minUsage.measurement === 'LM' ? (
                                'l/m'
                            ) : (
                                <>
                                    m<sup>2</sup>
                                </>
                            )}
                        </TableCell>
                        <TableCell>
                            <ul style={{paddingLeft: 5, margin: 0}}>
                                {minUsage.products?.map((product) => (
                                    <li key={product}>{product}</li>
                                ))}
                            </ul>
                        </TableCell>
                        <TableCell $align="right">
                            {formatPrice(minUsage.cost, {})}
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );

    const displayName = (minUsage: MinimumUsage) => {
        const id = minUsage?.materialId || minUsage?.edgeId;
        return id ? mapMaterialDisplayName(id) : null;
    };

    return (
        <Table>
            <tbody>
                <TableRow>
                    <TableHead>Material Type</TableHead>
                    <TableHead>Minimum Usage</TableHead>
                    <TableHead>Current Job Usage</TableHead>
                    <TableHead>Products Using Materials</TableHead>
                    <TableHead>Total Fee</TableHead>
                </TableRow>
                {materials.length > 0 && renderUsages(materials, 'Materials')}
                {edges.length > 0 && renderUsages(edges, 'Edging')}
            </tbody>
        </Table>
    );
};

export default MinimumUsageMoreInfo;
