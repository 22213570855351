import {FIELDSET_LAYOUT, SizePercentage} from 'components/customer/Product';
import {
    useAppContext,
    useProductContext,
    useNotificationContext,
} from 'contexts';
import {useField, useFormikContext} from 'formik';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Alert, Col, Form, Row} from 'react-bootstrap';
import {
    getCabinetTop,
    getHardwareMethods,
    getAdjustableLegsMethods,
    getSupplyMethods,
} from 'service';
import {
    Appliance,
    Boolean,
    DropdownDecorator,
    FormControl,
    Number,
    Size,
    MoreInfo,
    Radio,
    DynamicRadio,
    getIncludeDoorFacesLabel,
    getIncludeDoorFacesImage,
} from 'shared';
import excel from 'shared/Excel';
import {
    randomString,
    getFieldValue,
    hasOption,
    isOptionBoolean,
    isFieldEnabled,
    Icon,
} from 'shared/helpers';
import {EdgeFinishes} from 'components/customer/EdgeFinishes/EdgeFinishes';
import {validationErrorAppliesToField} from 'shared/validator';
import {isDeviceSmall, useTabletSize} from 'shared/helpers/DeviceSize';
import styled from 'styled-components';
import * as SharedTypes from 'shared/types';
import {useGetDrawerSystemQuery} from 'components/customer/Product/store/drawerRunnerApi';
import {useGetHingeStyle} from 'components/customer/Product/helpers/useGetHingeStyle';
import {
    useLazyGetAppliancesQuery,
    useLazyGetFingerPullStylesQuery,
} from 'components/customer/Product/store/productApi';
import {cloneDeep} from 'lodash';
import {FormGroup} from 'shared/components/Forms/FormGroup';

export const FIELD_TYPES = {
    NUMERIC: 'numeric',
    SIZE: 'size',
    TEXT: 'text',
    PART_TOP: 'part_top',
    SUPPLY_METHOD: 'assemblymethod',
    HARDWARE_METHOD: 'hardwaremethod',
    INCLUDE_DOOR_FACES: 'includedoorfaces',
    ADJUSTABLE_LEGS: 'adjustablelegs',
    MATERIAL: 'material',
    LABEL: 'label',
    SIZE_LABEL: 'size_label',
    BOOLEAN: 'boolean',
    DOOR_HANG: 'door_hang',
    SELECT: 'select',
    HINGE_STYLE: 'hinge_style',
    EDGE_FINISH: 'edge_finish',
    SHELF_TYPES: 'simple_shelf_type',
    SIZE_PERCENTAGE: 'size_percentage',
    FINGER_PULL: 'fingerpull',
    APPLIANCE: 'appliance',
    RADIO: 'radio',
};

const ButtonSlotWrapper = styled.div`
    display: flex;
    gap: 12px;
    & > div {
        width: 50%;
        padding: 0 !important;
        &.input-group {
            align-items: baseline;
        }
    }
`;

const isFieldVisible = (field, values, materialOptions) => {
    try {
        return excel.calculate(field.options.visible, values);
    } catch (e) {
        if (field.options.visible === 'cabinet_door.advanced') {
            return materialOptions?.cabinet_door?.advanced;
        } else return false;
    }
};

export const getVisibility = (
    field,
    fieldValue,
    values,
    indexedValues,
    materialOptions
) => {
    let visible = true;

    if (hasOption(field, 'visible')) {
        const controlledVisibility = field.options.visible;

        if (typeof controlledVisibility === 'function') {
            visible = controlledVisibility(values, fieldValue, indexedValues);
        } else {
            if (isOptionBoolean(field, 'visible')) {
                visible = controlledVisibility;
            } else {
                try {
                    visible = isFieldVisible(field, values, materialOptions);
                } catch (e) {
                    //
                }
            }
        }
    }

    return visible;
};

const getDataSource = (type, displayName = null) => {
    const DataSource = {
        [FIELD_TYPES.PART_TOP]: async (params) => {
            return await getCabinetTop(params);
        },
        [FIELD_TYPES.SUPPLY_METHOD]: getSupplyMethods,
        [FIELD_TYPES.HARDWARE_METHOD]: getHardwareMethods,
        [FIELD_TYPES.ADJUSTABLE_LEGS]: getAdjustableLegsMethods,
        [FIELD_TYPES.HINGE_STYLE]: async (params) => {
            const {
                hingeStyles,
                include_hardware: includeHardware,
                isAssemblyOnly,
            } = params;

            if (hingeStyles) {
                return cloneDeep(hingeStyles)
                    .filter(
                        ({name}) =>
                            !isAssemblyOnly ||
                            !name?.toLowerCase().includes('drill only')
                    )
                    .map((hingeStyle) => {
                        if (
                            includeHardware == 1 &&
                            hingeStyle.name
                                ?.toLowerCase()
                                .includes('drill only')
                        ) {
                            hingeStyle.disabled = true;
                        }
                        return hingeStyle;
                    });
            }
        },
        [FIELD_TYPES.EDGE_FINISH]: ({edgeFinishes}) => {
            return edgeFinishes;
        },
        [FIELD_TYPES.FINGER_PULL]: async (params) => {
            const {defaultValuesReference: data, fetchFingerPulStyles} = params;
            if (fetchFingerPulStyles) {
                let values = {
                    cabinet_drawer_gap: 0,
                    cabinet_drawer_top: 0,
                };

                if (data) {
                    values = data.current;
                }

                const {data: fingerPullStyles} = await fetchFingerPulStyles(
                    {
                        drawerGap: values.hasOwnProperty('cabinet_drawer_gap')
                            ? values.cabinet_drawer_gap
                            : values.cabinet_door_gap,
                        topMargin: values.hasOwnProperty('cabinet_drawer_top')
                            ? values.cabinet_drawer_top
                            : values.cabinet_door_top,
                    },
                    true
                );

                return fingerPullStyles;
            }
        },
        [FIELD_TYPES.APPLIANCE]: async (params) => {
            const {fetchAppliances, type} = params;

            if (typeof fetchAppliances === 'function') {
                const {data: appliances} = await fetchAppliances();

                if (appliances) {
                    return appliances.filter(
                        (appliance) => appliance.type == type
                    );
                }
            }
        },
        [FIELD_TYPES.INCLUDE_DOOR_FACES]: () => {
            const options = getIncludeDoorFacesImage(displayName);
            return [
                {
                    doorFaces: true,
                    value: 0,
                    name: `No ${getIncludeDoorFacesLabel(displayName)} Faces`,
                    description: '',
                    image: (
                        <Icon
                            iconName={options.noOptionImage}
                            className="icon-thumbnail"
                        />
                    ),
                    id: 0,
                },
                {
                    doorFaces: true,
                    value: 1,
                    name: `With ${getIncludeDoorFacesLabel(displayName)} Faces`,
                    description: '',
                    image: (
                        <Icon
                            iconName={options.yesOptionImage}
                            className="icon-thumbnail"
                        />
                    ),
                    id: 1,
                },
            ];
        },
    };

    return DataSource[type];
};

const formatSelectOptions = (options, values) => {
    if (Array.isArray(options)) {
        return options
            .filter((option) => {
                if (
                    typeof option === 'object' &&
                    option.hasOwnProperty('condition')
                ) {
                    try {
                        return excel.calculate(option.condition, values);
                    } catch (e) {
                        return true;
                    }
                }

                return true;
            })
            .map((option) => {
                if (typeof option !== 'object') {
                    return {id: option, name: option};
                } else {
                    return {
                        id: isNaN(option.value)
                            ? option.value
                            : parseInt(option.value),
                        name: option.label,
                    };
                }
            });
    } else {
        const formattedOptions = [];
        Object.keys(options).forEach((key) => {
            formattedOptions.push({
                id: parseInt(key),
                name: options[key],
            });
        });

        return formattedOptions;
    }
};

const Dropdown = {
    render({
        field,
        setFieldValue,
        selectHandler,
        dataSource,
        dataSourceParams,
        values,
        enabled,
        isQFP,
        ...props
    }) {
        if (
            field.hasOwnProperty('options') &&
            field.options.hasOwnProperty('selectOptions')
        ) {
            if (field.options.hasOwnProperty('isQFP') && field.options.isQFP) {
                dataSource = field.options.selectOptions;
            } else
                dataSource = formatSelectOptions(
                    field.options.selectOptions,
                    values
                );
        }

        let hasImage = false;
        let hasDescription = false;
        if (field.type === FIELD_TYPES.FINGER_PULL) {
            hasImage = 'imageUrl';
            hasDescription = 'description';
        }

        if (
            field.type === FIELD_TYPES.SUPPLY_METHOD ||
            field.type === FIELD_TYPES.HARDWARE_METHOD ||
            field.type === FIELD_TYPES.ADJUSTABLE_LEGS
        ) {
            hasDescription = 'description';
        }

        return (
            <DropdownDecorator
                setFieldValue={setFieldValue}
                selectHandler={selectHandler}
                dataSource={dataSource ? dataSource : getDataSource(field.type)}
                dataSourceParams={dataSourceParams}
                enabled={enabled}
                hasImage={hasImage}
                hasDescription={hasDescription}
                field={field}
                isQFP={isQFP}
                {...props}
            />
        );
    },
};

const Label = {
    render({value, style, ...props}) {
        if (typeof style === 'undefined') return value;

        switch (style) {
            case 'primary': // Styles that the "Alert" component handles
            case 'secondary':
            case 'success':
            case 'danger':
            case 'warning':
            case 'info':
            case 'dark':
            case 'light':
                return <Alert variant={style}>{value}</Alert>;
            case 'form_item':
                return <Form.Label style={{margin: 0}}>{value}</Form.Label>;
            case 'underneath_field':
                return (
                    <section
                        style={{
                            fontSize: '.75em',
                            textAlign: 'justify',
                        }}>
                        {value}
                    </section>
                );
        }

        return value;
    },
};

const DynamicRadioRender = {
    render({
        name,
        value,
        field,
        setFieldValue,
        selectHandler,
        extraHandler,
        isQFP,
        enabled,
        ...props
    }) {
        value = value > 0;

        if (isQFP) {
            setFieldValue = selectHandler;
        }

        const data = getDataSource(field.type, field.displayName);
        const hideYesOption = field?.options?.hideYesOption;
        const hideNoOption = field?.options?.hideNoOption;

        return (
            <DynamicRadio
                name={name}
                value={value}
                field={field}
                setFieldValue={setFieldValue}
                disabled={!enabled}
                options={data}
                isQFP={isQFP}
                yesOptionHidden={hideYesOption}
                noOptionHidden={hideNoOption}
            />
        );
    },
};

const DynamicSizeRender = {
    render({
        field,
        setFieldValue,
        dataSource,
        dataSourceParams,
        values,
        enabled,
        extraHandler,
        ...props
    }) {
        const withDeleteIcon =
            field?.options &&
            field?.options.hasOwnProperty('hasDeleteIcon') &&
            field?.options.hasDeleteIcon;
        return (
            <Size
                {...{field, ...props}}
                disabled={!enabled}
                metric="mm"
                inSpecsTab={props.inSpecsTab}
                withDeleteIcon={withDeleteIcon}
                deleteAction={field?.options?.deleteAction}
                customWidth={field?.options?.customWidth}
            />
        );
    },
};

const FieldStrategy = {
    [FIELD_TYPES.NUMERIC]: {
        render({
            field,
            setFieldValue,
            dataSource,
            dataSourceParams,
            values,
            enabled,
            extraHandler,
            isQFP,
            ...props
        }) {
            let min = 0;
            let max = 9999;

            if (
                field.hasOwnProperty('options') &&
                field.options.hasOwnProperty('maximum')
            ) {
                max = field.options.maximum;
                if (typeof max === 'string') {
                    max = excel.calculate(max, values);
                }
            }

            if (
                field.hasOwnProperty('options') &&
                field.options.hasOwnProperty('minimum')
            ) {
                min = field.options.minimum;
            }

            return (
                <Number
                    min={min}
                    max={max}
                    {...props}
                    in_sizes_tab={props.inSpecsTab}
                />
            );
        },
    },
    [FIELD_TYPES.SIZE]: DynamicSizeRender,
    [FIELD_TYPES.TEXT]: {
        render({
            field,
            setFieldValue,
            dataSource,
            dataSourceParams,
            values,
            enabled,
            extraHandler,
            showDialog,
            hideDialog,
            setCurrentTab,
            formStructure,
            value,
            ...props
        }) {
            let fieldValue = value;
            if (value === 0 && !field.value) fieldValue = '';

            return (
                <FormControl
                    as={
                        field.options.hasOwnProperty('multiLine') &&
                        field.options.multiLine
                            ? 'textarea'
                            : 'input'
                    }
                    rows={
                        field.options.hasOwnProperty('multiLine') &&
                        field.options.multiLine
                            ? 5
                            : 1
                    }
                    type="text"
                    disabled={!enabled}
                    maxLength={
                        field.options.hasOwnProperty('maximum')
                            ? field.options.maximum
                            : ''
                    }
                    value={fieldValue}
                    {...props}
                />
            );
        },
    },
    [FIELD_TYPES.LABEL]: Label,
    [FIELD_TYPES.SIZE_LABEL]: {
        render({
            field,
            setFieldValue,
            selectHandler,
            dataSource,
            dataSourceParams,
            values,
            enabled,
            extraHandler,
            isQFP,
            ...props
        }) {
            return (
                <Size
                    disabled={true}
                    {...props}
                    metric="mm"
                    in_sizes_tab={props.inSpecsTab}
                />
            );
        },
    },
    [FIELD_TYPES.BOOLEAN]: {
        render({
            name,
            value,
            field,
            setFieldValue,
            selectHandler,
            extraHandler,
            isQFP,
            ...props
        }) {
            const type = 'checkbox';

            // detect whether it is select box as well
            value = value > 0;

            if (isQFP) {
                setFieldValue = selectHandler;
            }

            // unique condition for cabinet_simple_shelves
            return (
                <Boolean
                    name={name}
                    value={name === 'cabinet_simple_shelves' ? !value : value}
                    field={field}
                    setFieldValue={(name_, value_) => {
                        if (name === 'cabinet_simple_shelves') {
                            setFieldValue(name_, !value_);
                        } else {
                            setFieldValue(name_, value_);
                        }
                    }}
                    type={type}
                />
            );
        },
    },
    [FIELD_TYPES.RADIO]: {
        render({
            name,
            value,
            field,
            setFieldValue,
            selectHandler,
            extraHandler,
            isQFP,
            ...props
        }) {
            // detect whether it is select box as well
            value = value > 0;

            if (isQFP) {
                setFieldValue = selectHandler;
            }

            return (
                <>
                    <Radio
                        name={name}
                        value={value}
                        field={field}
                        setFieldValue={(name_, value_) => {
                            setFieldValue(name_, value_);
                        }}
                    />
                </>
            );
        },
    },
    [FIELD_TYPES.SIZE_PERCENTAGE]: {
        render({
            field,
            fieldsetName,
            setFieldValue,
            selectHandler,
            dataSource,
            dataSourceParams,
            values,
            enabled,
            extraHandler,
            isQFP,
            ...props
        }) {
            return (
                <SizePercentage
                    selectHandler={
                        selectHandler ? selectHandler : setFieldValue
                    }
                    {...props}
                    disabled={!enabled}
                    fieldsetName={fieldsetName}
                    metric="mm"
                />
            );
        },
    },
    [FIELD_TYPES.PART_TOP]: Dropdown,
    [FIELD_TYPES.INCLUDE_DOOR_FACES]: DynamicRadioRender,
    [FIELD_TYPES.SUPPLY_METHOD]: DynamicRadioRender,
    [FIELD_TYPES.ADJUSTABLE_LEGS]: DynamicRadioRender,
    [FIELD_TYPES.HARDWARE_METHOD]: {
        render(props) {
            const hasDrillOnlyDrawer =
                Array.isArray(props.values?.drawers) &&
                props.values.drawers.some((drawer) => {
                    const drawerType = drawer?.drawer_type;
                    const selectedDrawerSystem = props.drawerSystems?.find(
                        (drawerSystem) => drawerSystem.id == drawerType
                    );

                    return !!selectedDrawerSystem?.drillOnly;
                });

            const selectedHingeStyle = props.hingeStyles?.find(
                (hingeStyle) => hingeStyle.id == props.values?.hinge_style
            );

            const isDrillOnly =
                typeof selectedHingeStyle?.name === 'string' &&
                selectedHingeStyle.name.includes('drill only');

            const shouldShowDialog =
                props.values?.cabinet_include_hardware &&
                (hasDrillOnlyDrawer || isDrillOnly);

            if (shouldShowDialog) {
                const specificMessage = {
                    title: 'Option Unavailable',
                    text: '<p>The following are not available with your hardware inclusions selection of Supply Hardware.</p>',
                };

                if (hasDrillOnlyDrawer) {
                    props.values.drawers.forEach((drawer) => {
                        const drawerType = drawer?.drawer_type;
                        const selectedDrawerSystem = props.drawerSystems?.find(
                            (drawerSystem) => drawerSystem.id === drawerType
                        );

                        if (selectedDrawerSystem?.drillOnly) {
                            specificMessage.text += `<li>Drawer - ${selectedDrawerSystem.name}</li>`;
                        }
                    });
                }

                if (isDrillOnly) {
                    specificMessage.text += `<li>Hinge style - ${selectedHingeStyle.name}</li>`;
                }

                if (props.values?.cabinet_include_assembly) {
                    props.setFieldValue('cabinet_include_assembly', 0);
                }
                props.setFieldValue('cabinet_include_hardware', 0);
                props.showDialog({
                    title: specificMessage.title,
                    message: specificMessage.text,
                    hideYesButton: true,
                    hideNoButton: true,
                    buttons: [
                        {
                            name: 'Ok',
                            show: true,
                        },
                    ],
                });
            }

            return DynamicRadioRender.render(props);
        },
    },
    [FIELD_TYPES.MATERIAL]: Dropdown,
    [FIELD_TYPES.SELECT]: Dropdown,
    [FIELD_TYPES.HINGE_STYLE]: {
        render(props) {
            if (
                props.hasOwnProperty('selectHandler') &&
                typeof props.selectHandler == 'function'
            ) {
                props.ogHandler = props.selectHandler;
            } else {
                props.ogHandler = props.setFieldValue;
            }

            props.selectHandler = (name, value, options) => {
                const drillOnlyHinge =
                    options && options.find((hinge) => hinge.id == value);
                if (drillOnlyHinge) {
                    if (
                        drillOnlyHinge.name
                            .toLowerCase()
                            .indexOf('drill only') >= 0
                    ) {
                        props.ogHandler('drill_only_hinge', true);
                        props.ogHandler(
                            'drill_only_hinge_name',
                            drillOnlyHinge.name
                        );
                    } else {
                        props.ogHandler('drill_only_hinge', false);
                        props.ogHandler(
                            'drill_only_hinge_name',
                            drillOnlyHinge.name
                        );
                    }
                }

                props.ogHandler(name, value);
            };

            return Dropdown.render(props);
        },
    },
    [FIELD_TYPES.EDGE_FINISH]: {
        render({
            field,
            selectHandler,
            setFieldValue,
            isQFP,
            randomId,
            fieldIndex,
            fieldsetName,
            fieldSetIndex,
            value,
        }) {
            return (
                <EdgeFinishes
                    index={fieldIndex == -1 ? 0 : fieldIndex}
                    fieldId={randomId}
                    fieldName={field.name}
                    selectHandler={selectHandler}
                    setFieldValue={setFieldValue}
                    isQFP={isQFP}
                    fieldsetName={fieldsetName}
                    fieldSetIndex={fieldSetIndex}
                    value={value}
                />
            );
        },
    },
    [FIELD_TYPES.SHELF_TYPES]: Dropdown,
    [FIELD_TYPES.FINGER_PULL]: {
        render(props) {
            if (
                props.hasOwnProperty('selectHandler') &&
                typeof props.selectHandler == 'function'
            ) {
                props.ogHandler = props.selectHandler;
            } else {
                props.ogHandler = props.setFieldValue;
            }

            props.selectHandler = (name, value, options) => {
                if (value == -1 || !props.values.hasOwnProperty('drawers')) {
                    props.ogHandler(name, value, options);
                }
                if (
                    value != -1 &&
                    props.values.hasOwnProperty('drawers') &&
                    props.values.drawers.length > 0
                ) {
                    props.showDialog({
                        title: 'Check Drawers',
                        message:
                            '<p>Selecting this finger pull style increases the gaps between the drawers.</p><p>Please re-check the drawer heights, runners before saving this product.</p>',
                        hideYesButton: true,
                        hideNoButton: true,
                        buttons: [
                            {
                                name: 'Cancel',
                                show: true,
                            },
                            {
                                name: 'Move to Drawers',
                                show: true,
                                action: () => {
                                    props.ogHandler(name, value, options);
                                    const filteredFormStructure =
                                        props.formStructure.filter(
                                            (fs) => fs?.layout != 'sidebar'
                                        );
                                    const productConfig = JSON.parse(
                                        JSON.stringify(filteredFormStructure)
                                    );
                                    props.setCurrentTab(
                                        productConfig.findIndex(
                                            (config) => config.name == 'Drawers'
                                        )
                                    );
                                },
                            },
                        ],
                    });
                }
            };

            return Dropdown.render(props);
        },
    },
    [FIELD_TYPES.APPLIANCE]: {
        render({extraHandler, isQFP, dataSourceParams, ...props}) {
            return (
                <Appliance
                    {...props}
                    dropdown={Dropdown}
                    dataSourceParams={dataSourceParams}
                />
            );
        },
    },
    [FIELD_TYPES.DOOR_HANG]: {
        render(options) {
            if (!options.isQFP) {
                options.selectHandler = (name, value) => {
                    if (value != 2) {
                        // if value is not none
                        if (
                            !options.values.hasOwnProperty('drillings') ||
                            (options.values.hasOwnProperty('drillings') &&
                                options.values.drillings.length == 0)
                        ) {
                            const drillings = [
                                {
                                    drilling_offset_y:
                                        options.defaultHingePosition,
                                },
                                {
                                    drilling_offset_y:
                                        options.defaultHingePosition,
                                },
                            ];

                            options.setFieldValue('drillings', drillings);
                        }
                    } else {
                        options.setFieldValue('drillings', []);
                    }

                    options.setFieldValue(name, value);
                };
            }

            return Dropdown.render(options);
        },
    },
};

export const Field = ({
    field,
    fieldsetName,
    dataSource = false,
    fieldSpan = 6,
    formControlSpan = 2,
    selectHandler = false,
    extraHandler = false,
    indexedValues = false,
    isQFP = false,
    fieldSetIndex,
    placeholder = '',
    isStackFields = false,
    cancelButton = null,
    buttonSlot = null,
    fieldHeaderName = null,
}: {
    field: SharedTypes.Field,
    fieldsetName: string,
    buttonSlot?: JSX.Element,
    fieldHeaderName?: React.ReactNode,
}) => {
    const {values, setFieldValue, errors} = useFormikContext();
    const {
        currentTab,
        productDataStore,
        getProductInfo,
        getInitialValue,
        getMaterialOptions,
        forty5Fields,
        showDialog,
        hideDialog,
        setCurrentTab,
        formStructure,
        defaultValuesReference,
        fieldIndex,
        validationData,
    } = useProductContext();

    const [fetchFingerPulStyles] = useLazyGetFingerPullStylesQuery();
    const [fetchAppliances] = useLazyGetAppliancesQuery();

    const {data: drawerSystems} = useGetDrawerSystemQuery();
    const {data: hingeStyles} = useGetHingeStyle(values.cabinet_type);
    const {userProfile} = useAppContext();
    const {messages} = useNotificationContext();

    const [dataSourceParams, setDataSourceParam] = useState();
    const isSmallDevice = isDeviceSmall();
    const isTabletSize = useTabletSize();

    const [fieldName, fieldType] = useMemo(() => {
        return [field.name, field.type];
    }, [field]);

    const isInvalid = useMemo(() => {
        let errorMessages = messages.errors;

        if (
            isQFP &&
            fieldIndex > -1 &&
            errorMessages.hasOwnProperty(fieldIndex)
        ) {
            // eslint-disable-next-line security/detect-object-injection -- Checked in the if statement above
            errorMessages = errorMessages[fieldIndex];
        }

        if (!Array.isArray(errorMessages) || errorMessages.length == 0) {
            return false;
        }

        if (errors && ['door_hinge_amount_1'].includes(fieldName)) {
            errorMessages = errorMessages?.filter((e) =>
                e.fields?.some((f) => Object.keys(errors).includes(f))
            );
        }

        return errorMessages.some((error) =>
            validationErrorAppliesToField(error, fieldName, fieldSetIndex)
        );
    }, [messages, isQFP, fieldIndex, errors, fieldName, fieldSetIndex]);

    const fieldLabel = useMemo(() => {
        let fieldLabel =
            field.hasOwnProperty('title') && field.title
                ? field.title
                : field.hasOwnProperty('displayName') && field.displayName
                ? field.displayName
                : false;

        if (
            fieldLabel &&
            fieldLabel.indexOf('{') > -1 &&
            fieldLabel.indexOf('}') > -1
        ) {
            fieldLabel = getFieldValue(fieldLabel, values, userProfile);
        }

        return fieldLabel;
    }, [field, values, userProfile]);

    const [formikField] = useField({name: field.name});

    const fieldValue = useMemo(() => {
        let fieldValue = indexedValues
            ? indexedValues[field.name]
            : formikField.value;

        if (
            fieldType === FIELD_TYPES.SIZE_LABEL &&
            typeof fieldValue === 'string'
        ) {
            fieldValue = getFieldValue(
                fieldValue,
                {...values, ...getMaterialOptions()},
                userProfile
            );
        }

        if (fieldType === FIELD_TYPES.LABEL) {
            if (
                field.value &&
                field.value.indexOf('{') > -1 &&
                field.value.indexOf('}') > -1
            ) {
                fieldValue = getFieldValue(
                    field.value,
                    {...values, ...getMaterialOptions()},
                    userProfile
                );
            } else fieldValue = field.value;
        }

        if (
            field.name &&
            (field.name.indexOf('hori_mid_rail_positions') > -1 ||
                field.name.indexOf('vert_mid_rail_positions') > -1)
        ) {
            return typeof fieldValue == 'undefined' ? '' : fieldValue;
        }
        fieldValue = typeof fieldValue === 'undefined' ? 0 : fieldValue;

        if (fieldValue === null && fieldType === FIELD_TYPES.SIZE_PERCENTAGE) {
            fieldValue = 0;
        }

        return fieldValue;
    }, [formikField, field, indexedValues, values, userProfile]);

    if (
        !forty5Fields.current.hasOwnProperty(fieldName) &&
        field.hasOwnProperty('options') &&
        field.options.hasOwnProperty('needs45') &&
        field.options.needs45
    ) {
        forty5Fields.current[fieldName] = fieldValue;
    }

    const visible = useMemo(
        () =>
            getVisibility(
                field,
                fieldValue,
                values,
                indexedValues,
                getMaterialOptions()
            ),
        [field, fieldValue, values, indexedValues]
    );

    const enabled = useMemo(() => {
        let enabled = true;

        if (hasOption(field, 'enabled')) {
            const controlledEnabled = field.options.enabled;

            if (typeof controlledEnabled === 'function') {
                enabled = controlledEnabled(values, fieldValue, indexedValues);
            } else {
                if (isOptionBoolean(field, 'enabled')) {
                    enabled = controlledEnabled;
                } else {
                    try {
                        enabled = isFieldEnabled(
                            field,
                            values,
                            getMaterialOptions()
                        );
                    } catch (e) {
                        // unable to calculate field enabled
                        // report to apm if needed
                    }
                }
            }
        }

        return enabled;
    }, [values, indexedValues, validationData, field, fieldValue]);

    const fieldStyles = useMemo(() => {
        const isDrawerEdges =
            fieldsetName === FIELDSET_LAYOUT.DRAWER_EDGES && isQFP;
        return {
            colStyle: {
                height: isDrawerEdges ? '40px' : null,
            },
            labelStyle: {
                paddingLeft: isDrawerEdges ? 30 : null,
            },
        };
    }, [fieldsetName]);

    useEffect(() => {
        if (!enabled && hasOption(field, 'forceDefaultValueWhenDisabled')) {
            try {
                const calculatedValue = excel.calculate(
                    getInitialValue(fieldName),
                    values
                );

                const isVisible = getVisibility(
                    field,
                    fieldValue,
                    values,
                    indexedValues,
                    getMaterialOptions()
                );

                if (values[fieldName] != calculatedValue && isVisible) {
                    if (isQFP && typeof selectHandler === 'function') {
                        selectHandler(fieldName, calculatedValue);
                    } else {
                        setFieldValue(fieldName, calculatedValue);
                    }
                }
            } catch (e) {
                // unable to calculate default value,
                // report to apm later
            }
        }
    }, [enabled]);

    useEffect(() => {
        try {
            if (
                field.hasOwnProperty('options') &&
                field.options.hasOwnProperty('needs45') &&
                field.options.needs45
            ) {
                if (
                    forty5Fields.current.cabinet_left_width !=
                    values.cabinet_left_width
                ) {
                    if (fieldName === 'cabinet_left_width') {
                        forty5Fields.current.cabinet_left_width =
                            values.cabinet_left_width;

                        let newLeftWidth;
                        if (
                            values.cabinet_left_width <=
                            values.cabinet_right_depth
                        ) {
                            newLeftWidth = excel.calculate(
                                'cabinet_right_depth + 1',
                                values
                            );
                            forty5Fields.current.cabinet_left_width =
                                newLeftWidth;
                        }

                        let newLeftDepth;
                        let newRightDepth = excel.calculate(
                            'cabinet_left_width - cabinet_right_width + cabinet_left_depth',
                            forty5Fields.current
                        );

                        if (newRightDepth <= 0) {
                            newLeftDepth = excel.calculate(
                                'cabinet_right_width - cabinet_left_width + 1',
                                forty5Fields.current
                            );
                            newRightDepth = 1;
                        }

                        setFieldValue('cabinet_right_depth', newRightDepth);
                        forty5Fields.current['cabinet_right_depth'] =
                            newRightDepth;
                        forty5Fields.current.widthChanged = true;

                        if (newLeftDepth) {
                            forty5Fields.current['cabinet_left_depth'] =
                                newLeftDepth;
                            setFieldValue('cabinet_left_depth', newLeftDepth);
                        }

                        if (newLeftWidth) {
                            setFieldValue('cabinet_left_width', newLeftWidth);
                        }
                    }
                }

                if (
                    forty5Fields.current.cabinet_right_width !=
                    values.cabinet_right_width
                ) {
                    if (fieldName === 'cabinet_right_width') {
                        forty5Fields.current.cabinet_right_width =
                            values.cabinet_right_width;
                        let newRightWidth;
                        if (
                            values.cabinet_right_width <=
                            values.cabinet_left_depth
                        ) {
                            newRightWidth = excel.calculate(
                                'cabinet_left_depth + 1',
                                values
                            );
                            forty5Fields.current.cabinet_right_width =
                                newRightWidth;
                        }

                        let newRightDepth;
                        let newLeftDepth = excel.calculate(
                            'cabinet_right_width - cabinet_left_width + cabinet_right_depth',
                            forty5Fields.current
                        );

                        if (newLeftDepth <= 0) {
                            newRightDepth = excel.calculate(
                                'cabinet_left_width - cabinet_right_width + 1',
                                forty5Fields.current
                            );
                            newLeftDepth = 1;
                        }

                        setFieldValue('cabinet_left_depth', newLeftDepth);
                        forty5Fields.current['cabinet_left_depth'] =
                            newLeftDepth;
                        forty5Fields.current.widthChanged = true;

                        if (newRightDepth) {
                            setFieldValue('cabinet_right_depth', newRightDepth);
                            forty5Fields.current['cabinet_right_depth'] =
                                newRightDepth;
                        }

                        if (newRightWidth) {
                            setFieldValue('cabinet_right_width', newRightWidth);
                        }
                    }
                }

                if (
                    forty5Fields.current.cabinet_left_depth !=
                    values.cabinet_left_depth
                ) {
                    if (fieldName === 'cabinet_left_depth') {
                        forty5Fields.current.cabinet_left_depth =
                            values.cabinet_left_depth;
                        const leftFace = excel.calculate(
                            'cabinet_right_width - cabinet_left_depth',
                            forty5Fields.current
                        );
                        const rightFace = excel.calculate(
                            'cabinet_left_width - cabinet_right_depth',
                            forty5Fields.current
                        );

                        if (
                            leftFace != rightFace &&
                            !forty5Fields.current.widthChanged
                        ) {
                            let newLeftDepth;

                            if (
                                values['cabinet_left_depth'] >
                                values['cabinet_right_width']
                            ) {
                                newLeftDepth = excel.calculate(
                                    'cabinet_right_width - 1',
                                    values
                                );
                                forty5Fields.current.cabinet_left_depth =
                                    newLeftDepth;
                            }

                            let newRightDepth = excel.calculate(
                                'cabinet_left_width - cabinet_right_width + cabinet_left_depth',
                                forty5Fields.current
                            );

                            if (newRightDepth <= 0) {
                                newLeftDepth = excel.calculate(
                                    'cabinet_right_width - cabinet_left_width + 1',
                                    forty5Fields.current
                                );
                                newRightDepth = 1;
                            }

                            setFieldValue('cabinet_right_depth', newRightDepth);
                            forty5Fields.current['cabinet_right_depth'] =
                                newRightDepth;

                            if (newLeftDepth) {
                                forty5Fields.current['cabinet_left_depth'] =
                                    newLeftDepth;
                                setFieldValue(
                                    'cabinet_left_depth',
                                    newLeftDepth
                                );
                            }
                        }

                        forty5Fields.current.widthChanged = false;
                    }
                }

                if (
                    forty5Fields.current.cabinet_right_depth !=
                    values.cabinet_right_depth
                ) {
                    if (fieldName === 'cabinet_right_depth') {
                        forty5Fields.current.cabinet_right_depth =
                            values.cabinet_right_depth;
                        const leftFace = excel.calculate(
                            'cabinet_right_width - cabinet_left_depth',
                            forty5Fields.current
                        );
                        const rightFace = excel.calculate(
                            'cabinet_left_width - cabinet_right_depth',
                            forty5Fields.current
                        );

                        if (
                            leftFace != rightFace &&
                            !forty5Fields.current.widthChanged
                        ) {
                            let newRightDepth;

                            if (
                                values['cabinet_right_depth'] >
                                values['cabinet_left_width']
                            ) {
                                newRightDepth = excel.calculate(
                                    'cabinet_left_width - 1',
                                    values
                                );
                                forty5Fields.current.cabinet_right_depth =
                                    newRightDepth;
                            }

                            let newLeftDepth = excel.calculate(
                                'cabinet_right_width - cabinet_left_width + cabinet_right_depth',
                                forty5Fields.current
                            );

                            if (newLeftDepth <= 0) {
                                newRightDepth = excel.calculate(
                                    'cabinet_left_width - cabinet_right_width + 1',
                                    forty5Fields.current
                                );
                                newLeftDepth = 1;
                            }

                            setFieldValue('cabinet_left_depth', newLeftDepth);
                            forty5Fields.current['cabinet_left_depth'] =
                                newLeftDepth;

                            if (newRightDepth) {
                                setFieldValue(
                                    'cabinet_right_depth',
                                    newRightDepth
                                );
                                forty5Fields.current['cabinet_right_depth'] =
                                    newRightDepth;
                            }
                        }

                        forty5Fields.current.widthChanged = false;
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
        // NOTE: Move this to separate hook helper
    }, [values, field]);

    const paramsEffectModifiers = isQFP
        ? [values.edge_colour, values.cabinet_include_hardware]
        : [false, false, false, false];

    useEffect(() => {
        switch (field.type) {
            case FIELD_TYPES.PART_TOP:
                let availableTops = getProductInfo('available_cabinet_tops');
                availableTops = availableTops
                    ? JSON.parse(`[${availableTops}]`)
                    : undefined;

                const showNone =
                    productDataStore.current.hasOwnProperty(
                        'has_none_in_tops'
                    ) && productDataStore.current.has_none_in_tops;

                setDataSourceParam({availableTops, showNone});
                break;

            case FIELD_TYPES.HINGE_STYLE:
                setDataSourceParam({
                    hingeStyles,
                    include_hardware: values.cabinet_include_hardware,
                    isAssemblyOnly: userProfile.isAssemblyOnly,
                });
                break;

            case FIELD_TYPES.FINGER_PULL:
                setDataSourceParam({
                    defaultValuesReference,
                    fetchFingerPulStyles,
                });
                break;

            case FIELD_TYPES.APPLIANCE:
                setDataSourceParam({fetchAppliances});
                break;
        }
    }, [
        ...paramsEffectModifiers,
        values.cabinet_include_hardware,
        hingeStyles,
        userProfile,
    ]);

    const inSpecsTab = currentTab === 0 && !isQFP;

    const memoModifiers = [
        fieldValue,
        selectHandler ? selectHandler : 0,
        isInvalid,
        dataSource ? dataSource : 0,
        dataSourceParams ? dataSourceParams : 0,
        enabled,
        extraHandler ? extraHandler : 0,
        isQFP,
        values,
        inSpecsTab,
    ];

    const randomId = useRef(randomString(5)).current;
    const renderedField = useMemo(() => {
        let formField = FieldStrategy[fieldType];
        const renderOptions = {
            name: fieldName,
            value: fieldValue,
            setFieldValue,
            selectHandler,
            isInvalid,
            field,
            dataSource,
            dataSourceParams,
            values,
            enabled,
            extraHandler,
            isQFP,
            showDialog,
            hideDialog,
            setCurrentTab,
            formStructure,
            randomId,
            fieldSetIndex,
            placeholder,
            fieldIndex,
            inSpecsTab,
        };

        if (
            fieldType == FIELD_TYPES.SELECT &&
            fieldName == FIELD_TYPES.DOOR_HANG
        ) {
            renderOptions.defaultHingePosition =
                userProfile?.manufacturerDefaultHingePosition;

            formField = FieldStrategy[FIELD_TYPES.DOOR_HANG];
        }

        if (typeof formField === 'undefined') {
            formField = FieldStrategy[FIELD_TYPES.TEXT];
        }

        if (
            fieldType === FIELD_TYPES.SIZE_PERCENTAGE ||
            fieldType === FIELD_TYPES.EDGE_FINISH
        ) {
            renderOptions.fieldsetName = fieldsetName;
        }

        if (fieldType === FIELD_TYPES.HARDWARE_METHOD) {
            renderOptions.drawerSystems = drawerSystems;
            renderOptions.hingeStyles = hingeStyles;
        }

        renderOptions.fieldSetGroupName = fieldsetName;

        return formField.render(renderOptions);
    }, memoModifiers);

    if (!visible) return <></>;

    const ButtonSlotContainer = !!buttonSlot
        ? ButtonSlotWrapper
        : React.Fragment;

    return (
        <Row className={fieldName}>
            {field.type === FIELD_TYPES.LABEL &&
            field.displayName == null &&
            (!field.options.hasOwnProperty('style') ||
                field.options.style != 'underneath_field') ? (
                <Col>
                    {FieldStrategy[FIELD_TYPES.LABEL].render({
                        name: fieldName,
                        value: fieldValue,
                        style: field.options.hasOwnProperty('style')
                            ? field.options.style
                            : 'light',
                    })}
                </Col>
            ) : (
                <>
                    {fieldHeaderName}
                    <Col
                        style={fieldStyles.colStyle}
                        data-cy={`${fieldName}`}
                        xs={
                            inSpecsTab && isTabletSize
                                ? fieldSpan === 8
                                    ? 12
                                    : fieldSpan
                                : fieldSpan
                        }>
                        {cancelButton}
                        {fieldType === FIELD_TYPES.APPLIANCE ? (
                            renderedField
                        ) : (
                            <FormGroup
                                controlId={`${field.name}_${randomId}`}
                                className={
                                    field.type == FIELD_TYPES.TEXT &&
                                    field.options.hasOwnProperty('multiLine') &&
                                    field.options.multiLine
                                        ? 'is-text-area'
                                        : ''
                                }
                                asRow={
                                    ![
                                        'cabinet_include_assembly',
                                        'cabinet_include_hardware',
                                        'include_drawer_faces',
                                        'cabinet_adjustable_legs',
                                    ].includes(field.name)
                                }
                                style={
                                    ![
                                        'cabinet_include_assembly',
                                        'cabinet_include_hardware',
                                        'include_drawer_faces',
                                        'cabinet_adjustable_legs',
                                    ].includes(field.name)
                                        ? {}
                                        : {flexDirection: 'column'}
                                }>
                                <Form.Label
                                    style={fieldStyles.labelStyle}
                                    column
                                    xs={
                                        isStackFields
                                            ? 12
                                            : inSpecsTab
                                            ? isSmallDevice
                                                ? undefined
                                                : 4
                                            : formControlSpan
                                    }>
                                    {fieldLabel
                                        ? `${fieldLabel}${
                                              isStackFields ? '' : ':'
                                          } `
                                        : null}
                                    {field.hasOwnProperty('options') &&
                                    field.options.hasOwnProperty('mandatory') &&
                                    field.options.mandatory ? (
                                        <span>*</span>
                                    ) : null}

                                    {field.hasOwnProperty('options') &&
                                    field.options.hasOwnProperty('moreInfo') &&
                                    field.options.moreInfo ? (
                                        <MoreInfo
                                            info={field.options.moreInfo}
                                            scope={
                                                productDataStore.current &&
                                                productDataStore.current
                                                    .cabinet &&
                                                productDataStore.current.cabinet
                                                    .attributes
                                                    ? productDataStore.current
                                                          .cabinet.attributes
                                                    : {}
                                            }
                                            styleOverride={
                                                isQFP
                                                    ? field.options?.multiLine
                                                        ? {right: 10, top: 2}
                                                        : {right: -9, top: 2}
                                                    : {}
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Form.Label>
                                <Col
                                    xs={
                                        isStackFields
                                            ? 12
                                            : inSpecsTab
                                            ? isSmallDevice
                                                ? 'auto'
                                                : 8
                                            : 12 - formControlSpan
                                    }>
                                    <ButtonSlotContainer>
                                        {field.type === FIELD_TYPES.LABEL ? (
                                            <FormGroup
                                                style={
                                                    !field.options.hasOwnProperty(
                                                        'style'
                                                    ) ||
                                                    field.options.style !=
                                                        'underneath_field'
                                                        ? {margin: '5px 0 0 0'}
                                                        : {}
                                                }>
                                                {FieldStrategy[
                                                    FIELD_TYPES.LABEL
                                                ].render({
                                                    name: fieldName,
                                                    value: fieldValue,
                                                    style: field.options.hasOwnProperty(
                                                        'style'
                                                    )
                                                        ? field.options.style
                                                        : 'form_item',
                                                })}
                                            </FormGroup>
                                        ) : (
                                            renderedField
                                        )}
                                        {buttonSlot}
                                    </ButtonSlotContainer>
                                </Col>
                            </FormGroup>
                        )}
                    </Col>
                </>
            )}
        </Row>
    );
};
